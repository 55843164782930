/* eslint-disable react/prop-types */
import { Chip, IconButton, TableCell, TableRow } from "@material-ui/core"
import React from "react"
import { useTableEstimationStyles } from "components/sales-quotation/constants"
import { Add } from "@material-ui/icons"
import AsyncDropdown from "components/common/input/AsyncDropdown"

import InvoiceFormContext from "./InvoiceFormContex"

function SalesQuotationPicker({
  onAddItem = () => {},
  selectedSalesQuotations = [],
  onDeleteItem = () => {},
  fetchSalesQuotationData = () => [],
  fetchPOData,
  label = "PO. Number",
}) {
  const classes = useTableEstimationStyles()
  const { selectedCustomer, rateIn, invoiceType } = React.useContext(
    InvoiceFormContext
  )

  const [selectedSlsQuot, setSelectedSlsQuot] = React.useState(null)

  const fetchPurchaseOrders = React.useCallback(
    async (val) => {
      if (!selectedCustomer || !rateIn) return []
      if (typeof fetchPOData === "function") {
        const params = {
          customerId: selectedCustomer.idCust,
          rateInId: rateIn.idCurr,
          poNumber: val,
          limit: 10,
        }
        if (invoiceType === "INVOICE") params.processTypeId = 5
        const response = await fetchPOData(params)

        return response
      }
      const response = await fetchSalesQuotationData({
        customerId: selectedCustomer.idCust,
        rateInId: rateIn.idCurr,
        slsQuotNumber: val,
        limit: 10,
      })
      return response
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCustomer, rateIn, invoiceType]
  )

  return (
    <TableRow>
      <TableCell colSpan={5} className={classes.addItemCell}>
        <div className={classes.addItemContainer}>
          <IconButton
            size="small"
            variant="contained"
            color="default"
            className={classes.addButton}
            disabled={!selectedSlsQuot}
            onClick={() => {
              onAddItem(selectedSlsQuot)
              setSelectedSlsQuot(null)
            }}
          >
            <Add />
          </IconButton>
          <AsyncDropdown
            fetchData={fetchPurchaseOrders}
            getOptionLabel={(opt) => {
              return opt?.poNumber || opt?.slsQuotNumber
            }}
            value={selectedSlsQuot}
            onChange={(val) => {
              setSelectedSlsQuot(val)
            }}
            getOptionSelected={(opt, val) =>
              opt?.idSalesQuotation === val?.idSalesQuotation
            }
            getOptionDisabled={(opt) => {
              if (label !== "PO. Number") {
                const curr = selectedSalesQuotations.filter(
                  (item) => item.idSalesQuotation === opt?.idSalesQuotation
                )[0]
                return !!curr
              }
              const curr = selectedSalesQuotations.filter(
                (item) =>
                  item.poNumber === opt?.poNumber &&
                  item.idSalesQuotation === opt?.idSalesQuotation
              )[0]
              return !!curr
            }}
            style={{ marginLeft: "12px", width: "250px", padding: 8 }}
            inputLabel={label}
          />
        </div>
      </TableCell>
      <TableCell colSpan={2}>
        {selectedSalesQuotations.map((item, i) => (
          <Chip
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            label={
              item?.poNumber
                ? `${item?.poNumber} (${item?.slsQuotNumber})`
                : item?.slsQuotNumber
            }
            variant="outlined"
            onDelete={() => onDeleteItem(item)}
          />
        ))}
      </TableCell>
    </TableRow>
  )
}

export default SalesQuotationPicker
