/* eslint-disable react/prop-types */
// import MomentUtils from "@date-io/moment/build"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import FilterBox from "components/common/FilterBox"
import React, { useEffect, useState } from "react"

function InvoiceListFilter({ onParamsChange = () => {} }) {
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [invoiceType, setInvoiceType] = useState(null)
  const [month, setMonth] = useState("")
  // const [year, setYear] = React.useState(null)

  useEffect(() => {
    const params = {}

    if (dateStart) params.dateStart = dateStart
    if (dateEnd) params.dateEnd = dateEnd
    if (invoiceType) params.invoiceType = invoiceType
    if (month) params.month = month
    // if (year) {
    //   // eslint-disable-next-line no-underscore-dangle
    //   const date = new Date(year._d)
    //   params.year = date.getFullYear()
    // }

    if (onParamsChange) {
      onParamsChange(params)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateEnd, dateStart, invoiceType, month])

  return (
    <FilterBox>
      <div style={{ display: "flex", gap: "1rem" }}>
        <TextField
          type="date"
          margin="dense"
          label="From"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={dateStart}
          onChange={(e) => setDateStart(e.target.value)}
        />
        <TextField
          type="date"
          margin="dense"
          label="To"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={dateEnd}
          onChange={(e) => setDateEnd(e.target.value)}
        />
        <TextField
          type="month"
          margin="dense"
          label="Month"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={month}
          onChange={(e) => setMonth(e.target.value)}
        />
        {/* <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            views={["year"]}
            label="Year"
            value={year}
            onChange={(val) => setYear(val)}
            TextFieldComponent={(params) => {
              return <TextField {...params} />
            }}
            margin="dense"
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider> */}
        <FormControl margin="dense" style={{ width: 150 }}>
          <InputLabel id="invoice-type-label">Type</InputLabel>
          <Select
            defaultValue={invoiceType || ""}
            onChange={(e) => {
              const { value } = e.target
              setInvoiceType(value)
            }}
            name="invoiceType"
            id="invoice-type-select"
            labelId="invoice-type-label"
          >
            <MenuItem value="INVOICE">Invoice</MenuItem>
            <MenuItem value="INVOICE EXPORT">Invoice Export</MenuItem>
            <MenuItem value="DEBIT NOTE">Debit Note</MenuItem>
            <MenuItem value="DOWN PAYMENT">Down Payment</MenuItem>
          </Select>
        </FormControl>
      </div>
    </FilterBox>
  )
}

export default InvoiceListFilter
