import React from "react"
import clsx from "clsx"
import t from "prop-types"

/**
 * Create table data given array of string columns, 2D string data matrix,
 * andd columnProps
 * @param {*} param0
 */

const TableLong = ({
  data,
  columns,
  columnProps,
  className,
  tfoot = null,
  ...otherProps
}) => {
  return (
    <table
      cellSpacing={0}
      id="material-cost"
      {...otherProps}
      className={clsx("table-long", className)}
    >
      <thead>
        <tr>
          {columns.map((col, i) => (
            <th key={i} {...columnProps[i]}>
              {col}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(data || []).map((row, j) => (
          <tr key={j}>
            {row.map((col, i) => (
              <td key={i} {...columnProps[i]}>
                {col}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {tfoot}
    </table>
  )
}

TableLong.propTypes = {
  data: t.arrayOf(t.arrayOf(t.node)),
  columns: t.arrayOf(t.node),
  tfoot: t.node,
}

export default TableLong
