/* eslint-disable react/prop-types */

import { Button } from "@material-ui/core"
import StandardTextField from "components/common/input/StandardTextField"
import React, { useState } from "react"

function WorkOrderTrialDateForm({ defaultValue, onSubmit = () => {} }) {
  const [trialDate, setTrialDate] = useState(defaultValue?.trialDate || "")

  return (
    <div style={{ display: "flex", flexDirection: "column", maxWidth: 200 }}>
      <StandardTextField
        type="date"
        value={trialDate}
        onChange={(e) => setTrialDate(e.target.value)}
        margin="dense"
        variant="outlined"
        label="Trial Date"
        InputLabelProps={{ shrink: true }}
      />
      <Button
        onClick={() => onSubmit(trialDate)}
        color="primary"
        variant="contained"
        style={{ marginTop: 6 }}
        disabled={!trialDate}
      >
        Submit
      </Button>
    </div>
  )
}

export default WorkOrderTrialDateForm
