import React from 'react'
import BaseDialog from './BaseDialog'

export default function DeleteDialog({ dialogTitle, contentComponent, ...otherProps }) {
  return (<BaseDialog
    {...otherProps}
    dialogTitle={dialogTitle ?? "Delete Item?"}
    contentComponent={contentComponent ?? "Are you sure you want to delete this item?"}
    confirmButtonTitle='Delete'
    cancelButtonTitle='Cancel'
    confirmButtonProps={{
      color: 'secondary',
      variant: 'contained'
    }}
    cancelButtonProps={{
      color: 'primary',
      variant: 'outlined'
    }}
  />)
}