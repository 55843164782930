/* eslint-disable react/prop-types */
import FileField from "components/common/input/FileField"
import React, { useState } from "react"
import { Controller } from "react-hook-form"

function EstimateItemAttachmentForm({ control, index, defaultValue }) {
  const [value, setValue] = useState(defaultValue ?? "")
  return (
    <Controller
      defaultValue={value}
      control={control}
      name={`estimateList[${index}].attachment`}
      render={({ onChange }) => {
        return (
          <FileField
            value={value}
            onChange={(val) => {
              onChange(val)
              setValue(val)
            }}
            disableDrop
            fullWidth
            imageDisplayProp={{
              maxWidth: 300,
              maxHeight: 300,
            }}
            accept="image/png, image/jpeg, application/pdf"
            style={{ width: 250 }}
          />
        )
      }}
    />
  )
}

export default EstimateItemAttachmentForm
