import {
  adjustDefaultParams,
  getDataAndCountFromResponse,
  getQueryForAllAndCount,
  handleSilentApiError,
  newDictToStringParam,
} from "./utils"
import { ENDPOINT, axios } from "./constants"

const ITEM_DETAIL_QUERY = `
  idDeliveryNote
  status
  customerId
  currencyId
  attnName
  address
  phone
  fax
  deliveryDate
  poDate
  poNumber
  preparedById
  checkedById
  deliveryById
  notes
  dnNumber
  isTemporary
  temporaryDnNumber
  currency {
    code
    description
    idCurr
  }
  relatedInvoice {
    edges {
      node {
        status
      }
    }
  }
  customer{
    idCust
    custCode
    name
    address
    phoneNumber
    faxNumber
    email
  }
  preparedBy{
    idUser
    name
    employeeNumber
  }
  checkedBy{
    idUser
    name
    employeeNumber
  }
  deliveryBy{
    idUser
    name
    employeeNumber
  }
  vehicleNumber
  productDetails{
    idDnDetail
    description
    quantity
    unit
    remarks
    salesEstimateId
    mouldCode
    unitPrice
    sequence
    process
    projectName
    weight
    qcNumber
    relatedInvoiceDetail {
      edges {
        node {
          idInvoiceDetail
          invoice {
            status
          }
        }
      }
    }
    salesEstimate {
      handling
      reductionHistory
      quantity
      description
      quantity
      idSalesEstimate
      uom {
        name
      }
      slsPurchaseOrder {
        poNumber
        poDate
      }
      relatedWorkOrderEstimate {
        edges{
          node {
            idWorkOrderEstimate
            workOrder {
              woNumber
            }
            mouldCode
            status
          }
        }
      }
      slsQuot {
        poNumber
        slsQuotDate
        slsQuotNumber
        poDate
      }
      estQuot {
        processCostHandling
        materialCostHandling
        additionalCostHandling
        processCosts {
          id
          time
          processId
          process {
            cost
          }
        }
        materialCosts {
          materialId
          dimension
          quantity      
          sequence
          unitPrice
          material {
            materialCode
            unitPrice
            dimension
            materialType {
              density
              name
            }
          }
        }
        additionalCosts {
          quantity
          process
          cost
          handling
        }
      }
    }
  }
  `

export default class ApiDeliveryNotes {
  static async get(inpParams = {}) {
    const params = adjustDefaultParams(inpParams)
    let query = `
      query {
        allDeliveryNoteImpl {
            ${ITEM_DETAIL_QUERY}
        }
      }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return getDataAndCountFromResponse(response)
  }

  static async getItem(id) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
          query { 
            getDeliveryNoteImpl (idDeliveryNote: ${id}) {
              ${ITEM_DETAIL_QUERY}
            }
          }`,
    })
    handleSilentApiError(response)

    const {
      productDetails,
      ...otherItems
    } = response.data.data.getDeliveryNoteImpl

    return {
      data: {
        ...otherItems,
        productDetails: productDetails.map((pd) => {
          const obj = { ...pd }
          if (obj.qcNumber) {
            obj.outgoingQc = {
              qcNumber: obj.qcNumber,
            }
          }
          return obj
        }),
      },
    }
  }

  static async create(payload) {
    const payloadAsParam = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
        mutation {
        saveDeliveryNoteImpl(${payloadAsParam}) {
            deliveryNoteImpl {
              ${ITEM_DETAIL_QUERY}
            }
          }
      }`,
    })
    handleSilentApiError(response)

    return {
      data: response.data.data.saveDeliveryNoteImpl.deliveryNoteImpl,
    }
  }

  static async update(id, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idDeliveryNote: parseInt(id, 10),
    })
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
        mutation {
          updateDeliveryNoteImpl (${payloadAsParam}) {
            deliveryNoteImpl{
              ${ITEM_DETAIL_QUERY}
            }
          }
        }`,
    })
    handleSilentApiError(response)

    const {
      productDetails,
      ...otherItems
    } = response.data.data.updateDeliveryNoteImpl.deliveryNoteImpl

    return {
      data: {
        ...otherItems,
        productDetails: productDetails.map((pd) => {
          const obj = { ...pd }
          if (obj.qcNumber) {
            obj.outgoingQc = {
              qcNumber: obj.qcNumber,
            }
          }
          return obj
        }),
      },
    }
  }

  static async getConfig() {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        getDeliveryNoteConfigImpl {
          poFilter
        }
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.getDeliveryNoteConfigImpl }
  }

  static async getDeliveryNoteYear() {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query {
        allDeliveryNoteYearImpl
      }`,
    })

    handleSilentApiError(response)

    return {
      data: response.data.data.allDeliveryNoteYearImpl,
    }
  }
}
