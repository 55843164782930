import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core"
import t from "prop-types"
import Launch from "@material-ui/icons/Launch"
import clsx from "clsx"
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react"
import getActive from "utils/datatables/getActive"

const useStyles = makeStyles(() => ({
  "@global": {
    ".scm-img__wrapper": {
      overflow: "hidden",
      position: "relative",
      background: "white",
      zIndex: 0,
      "&:hover, &:focus-within": {
        "& > .scm-image-display__button": {
          opacity: 1,
        },
        "& > .scm-image__overlay": {
          opacity: 1,
        },
      },
    },
    ".scm-image__image": {
      zIndex: -1,
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      transition: "0.3s opacity ease",
      opacity: 1,
    },
    ".scm-image__image.scm-image__image--hide": {
      opacity: 0,
    },
    ".scm-img__loader": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    ".scm-image-display__button": {
      transition: "0.3s opacity ease",
      opacity: 0,
      position: "absolute",
      top: "8px",
      right: "8px",
      color: "white",
    },
    ".scm-image__overlay": {
      transition: "0.3s opacity ease",
      opacity: 0,
      position: "absolute",
      top: "0",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(180deg, #00000073 0,#00000054 36px, #0000001a 56px, transparent 75px)",
    },
    ".scm-img__retry": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    ".scm-img__retry-content": {
      flexFlow: "column",
      display: "flex",
      alignItems: "center",
      position: "relative",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
  },
}))

// Adapted from Vuetify's VImg
// https://github.com/vuetifyjs/vuetify/blob/1bf047344a04999276bf17c781eab174ec2794c8/packages/vuetify/src/components/VImg/VImg.ts#L88

const ImageDisplay = ({
  className,
  aspectRatio,
  maxWidth,
  maxHeight,
  width,
  height,
  src,
  children,
  showOpenInNewTab,
  loadingComponent,
  renderError,
  elevation,
}) => {
  useStyles()

  const cleanedSrc = (src || "").trim()

  const openInNewTab = () => {
    window.open(cleanedSrc)
  }

  const [loading, setLoading] = useState(true)
  const [calculatedAspectRatio, setCalculatedAspectRatio] = useState()
  const [error, setError] = useState(null)

  const [flagRetry, setFlagRetry] = useState(false)
  const retry = useCallback(() => setFlagRetry((flag) => !flag), [])

  const success = !loading && !error
  const [showImage, setShowImage] = useState(false)

  useEffect(() => {
    if (success) {
      const requestId = window.requestAnimationFrame(() =>
        setShowImage(success)
      )
      return () => {
        window.cancelAnimationFrame(requestId)
      }
    }
    setShowImage(false)
    return () => {}
  }, [success])

  useLayoutEffect(() => {
    const { checkActive, cleanupEffect } = getActive()
    if (!cleanedSrc) {
      return cleanupEffect
    }

    const image = new Image()
    const onLoad = () => {
      if (!checkActive()) {
        return
      }
      setLoading(false)
      setCalculatedAspectRatio(image.width / image.height)
    }

    image.onload = () => {
      if (image.decode) {
        image
          .decode()
          .catch((err) => {
            console.log(err)
          })
          .then(onLoad)
      } else {
        onLoad()
      }
    }
    image.onerror = (err) => {
      if (!checkActive()) {
        return
      }
      setLoading(false)
      setError(err)
    }

    image.src = cleanedSrc
    setLoading(true)
    setError(null)
    return cleanupEffect
  }, [cleanedSrc, flagRetry])

  const aspect = aspectRatio || calculatedAspectRatio || 1
  let errorComponent = null
  if (error) {
    if (typeof renderError === "function") {
      errorComponent = errorComponent({ error, retry })
    }
    if (!errorComponent) {
      errorComponent = (
        <div className="scm-img__retry">
          <div className="scm-img__retry-content">
            Cannot load image.
            <Button variant="outlined" onClick={retry}>
              Retry
            </Button>
          </div>
        </div>
      )
    } else {
      errorComponent = <div className="scm-img__retry">{errorComponent}</div>
    }
  }

  return (
    <Card
      elevation={elevation}
      style={{
        maxWidth,
        maxHeight,
        height,
        width,
      }}
      className={clsx(
        "scm-img__wrapper",
        className,
        loading && "scm-img__wrapper--loading"
      )}
    >
      {loading &&
        (loadingComponent || (
          <div className="scm-img__loader">
            <CircularProgress variant="indeterminate" />
          </div>
        ))}
      <div
        className="scm-img__responsive-sizer"
        style={{ paddingBottom: `${(1 / aspect) * 100}%` }}
      />
      <div
        className={clsx(
          "scm-image__image",
          !showImage && "scm-image__image--hide"
        )}
        style={{
          backgroundImage: `url("${src}")`,
        }}
      />
      <div className="scm-image__content">{children}</div>
      {!loading && !error && showOpenInNewTab && (
        <>
          <div className="scm-image__overlay" />
          <IconButton
            size="small"
            className="scm-image-display__button"
            onClick={openInNewTab}
          >
            <Launch />
          </IconButton>
        </>
      )}
      {errorComponent}
      {children}
    </Card>
  )
}

ImageDisplay.propTypes = {
  className: t.string,
  aspectRatio: t.number,
  maxWidth: t.oneOfType([t.number, t.string]),
  maxHeight: t.oneOfType([t.number, t.string]),
  width: t.oneOfType([t.number, t.string]),
  height: t.oneOfType([t.number, t.string]),
  src: t.string,
  children: t.node,
  showOpenInNewTab: t.bool,
  loadingComponent: t.node,
  renderError: t.func,
  elevation: t.number,
}

ImageDisplay.defaultProps = {
  className: "",
  aspectRatio: null,
  maxWidth: 250,
  maxHeight: 250,
  width: null,
  height: null,
  src: null,
  children: null,
  showOpenInNewTab: false,
  loadingComponent: null,
  renderError: null,
  elevation: 4,
}

ImageDisplay.displayName = ImageDisplay

export default ImageDisplay
