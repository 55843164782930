import { makeStyles } from "@material-ui/core"

export const useTyphoStyles = makeStyles({
  companyName: {
    fontWeight: "bolder",
    fontStyle: "italic",
    fontSize: "1rem",
    paddingLeft: "1rem",
  },
  title: {
    fontWeight: "bolder",
    fontSize: "1rem",
  },
  normal: {
    fontSize: "0.75rem",
  },
  small: {
    fontSize: "0.5rem",
  },
  logo: {
    width: "12mm",
  },
  leftAlign: {
    textAlign: "left",
  },
  rightAlign: {
    textAlign: "right",
  },
})

export const useAlignStyles = makeStyles({
  columnLeftAlign: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  columnRightAlign: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  rowMiddleAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  rowRightAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  rowChild: {
    flexGrow: 1,
  },
  rowChildBasisMedium: {
    flexBasis: "370px",
  },
  rowChildBasisSmall: {
    flexBasis: "277px",
  },
  separator: {
    paddingTop: "2rem",
  },
  headerOverflow: {
    width: "740px",
  },
})

export const useTableStyles = makeStyles({
  table: {
    "& thead > * > *": {
      borderTop: "0.3mm solid black",
      borderBottom: "0.3mm solid black",
      padding: "0.3mm 2.0mm",
    },
    "& tbody > * > *": {
      padding: "0.3mm 2.0mm",
    },
    "& tbody > *:last-child > *": {
      borderBottom: "0.3mm solid black",
    },
  },
  tableBordered: {
    "& tbody > * > *": {
      borderBottom: "1px solid black",
    },
  },
  approvalTable: {
    tableLayout: "fixed",
    width: "320px",
    // width: "370px",
    border: "1px solid black",
    borderCollapse: "collapse",
    "& thead > * > *": {
      border: "1px solid black",
      borderCollapse: "collapse",
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
    },
    "& tbody > * > *": {
      border: "1px solid black",
      borderCollapse: "collapse",
      paddingTop: "2rem",
      paddingBottom: "2rem",
    },
    "& tfoot > * > *": {
      border: "1px solid black",
      borderCollapse: "collapse",
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
    },
    breakInside: "avoid",
    pageBreakInside: "avoid",
  },
  partGroup: {
    backgroundColor: "yellow",
  },
})
