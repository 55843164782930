/* eslint-disable react/prop-types */
import { Grid, Typography } from "@material-ui/core"
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from "react"
import InvoiceDownPaymentTable from "./InvoiceDownPaymentTable"
import InvoiceExportTable from "./InvoiceExportTable"
import InvoiceFormContext from "./InvoiceFormContex"
import ProductDescriptionTable from "./ProductDescriptionTable"
import ProductDNTable from "./ProductDNTable"

const InvoiceDescriptionForm = forwardRef(
  (
    {
      initialValue,
      fetchDeliveryNoteData,
      fetchSalesQuotationData,
      fetchPOData,
      fetchInitDeliveryNoteData,
      fetchInitPOData,
      fetchInitSalesQuotationData,
    },
    ref
  ) => {
    const productTableRef = useRef()
    useImperativeHandle(ref, () => ({
      getFormData: () => {
        return productTableRef.current?.getFormData()
      },
      submitForm: () => {},
    }))

    const { invoiceType, source } = useContext(InvoiceFormContext)

    let table = (
      <ProductDNTable ref={productTableRef} initialValue={initialValue} />
    )
    if (
      ["INVOICE", "DOWN PAYMENT"].includes(invoiceType) &&
      source === "DELIVERY NOTE"
    ) {
      table = (
        <ProductDescriptionTable
          ref={productTableRef}
          initialValue={initialValue}
          fetchDeliveryNoteData={fetchDeliveryNoteData}
          fetchInitDeliveryNoteData={fetchInitDeliveryNoteData}
        />
      )
    } else if (
      ["INVOICE", "DOWN PAYMENT"].includes(invoiceType) &&
      source === "PURCHASE ORDER"
    ) {
      table = (
        <InvoiceDownPaymentTable
          ref={productTableRef}
          fetchSalesQuotationData={fetchSalesQuotationData}
          fetchPOData={fetchPOData}
          initialValue={initialValue}
          fetchInitPOData={fetchInitPOData}
        />
      )
    } else if (invoiceType === "INVOICE EXPORT") {
      table = (
        <>
          <InvoiceExportTable
            ref={productTableRef}
            fetchSalesQuotationData={fetchSalesQuotationData}
            initialValue={initialValue}
            fetchInitSalesQuotationData={fetchInitSalesQuotationData}
          />
        </>
      )
    }
    return (
      <Grid item md={12}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Description
        </Typography>

        {table}
      </Grid>
    )
  }
)

export default InvoiceDescriptionForm
