/* eslint-disable react/prop-types */
import { IconButton, TableCell, TableRow, TextField } from "@material-ui/core"

import React, { useMemo, useContext } from "react"
import { numberFormat } from "utils/thousand-separator"
import { Delete } from "@material-ui/icons"
import InvoiceFormContext from "./InvoiceFormContex"
import { currencyOperation } from "./utils"

function InvoiceExportRow({
  item,
  index,
  register,
  errors,
  onDeleteButtonClick = () => {},
}) {
  const {
    selectedCurrency,
    paymentPercentage,
    currencyRate,
    rateIn,
  } = useContext(InvoiceFormContext)

  const totalCostWithCurrency = useMemo(() => {
    return currencyOperation(item.totalCost, rateIn, currencyRate)
  }, [currencyRate, item.totalCost, rateIn])
  const totalCostWithPercentage = useMemo(() => {
    if (!paymentPercentage) return totalCostWithCurrency
    return totalCostWithCurrency * (0 + paymentPercentage / 100)
  }, [paymentPercentage, totalCostWithCurrency])

  const getFormAttribute = (name, defValue = null) => {
    const errorObj = (errors[index] || {})[name]
    return {
      name: `productDescriptions[${index}].${name}`,
      defaultValue: item[name] ?? defValue,
      inputRef: register,
      error: !!errorObj,
      helperText: errorObj?.message,
      variant: "outlined",
      fullWidth: true,
      margin: "dense",
    }
  }
  return (
    <TableRow>
      <TableCell>{item.slsQuot?.slsQuotNumber}</TableCell>
      <TableCell>{item.slsQuot?.poNumber}</TableCell>
      <TableCell>
        <TextField {...getFormAttribute("description")} multiline />
        <input
          type="hidden"
          name={`productDescriptions[${index}].slsEstimateId`}
          value={item.slsEstimateId}
          ref={register}
        />
      </TableCell>
      <TableCell>
        {new Intl.NumberFormat().format(item.quantity)}
        <input
          type="hidden"
          name={`productDescriptions[${index}].quantity`}
          value={item.quantity}
          ref={register}
        />
      </TableCell>
      <TableCell>
        {item.uom?.name}
        <input
          type="hidden"
          name={`productDescriptions[${index}].unit`}
          value={item.uom?.name}
          ref={register}
        />
      </TableCell>
      <TableCell style={{ textAlign: "right" }}>
        {selectedCurrency?.code}{" "}
        {numberFormat(
          Math.ceil(totalCostWithCurrency / item.quantity),
          0,
          ",",
          "."
        )}
        <input
          type="hidden"
          name={`productDescriptions[${index}].unitPrice`}
          value={item.totalCost}
          ref={register}
        />
      </TableCell>

      <TableCell style={{ textAlign: "right" }}>
        {selectedCurrency?.code}{" "}
        {numberFormat(Math.ceil(totalCostWithPercentage), 0, ",", ".")}
      </TableCell>
      <TableCell>
        <TextField
          {...getFormAttribute("sequence")}
          defaultValue={item.sequence || index + 1}
          type="number"
        />
      </TableCell>
      <TableCell>
        <TextField
          {...getFormAttribute("remark")}
          multiline
          style={{ width: "15rem" }}
        />
      </TableCell>
      <TableCell>
        <TextField
          {...getFormAttribute("netWeight", 0)}
          style={{ width: "8rem" }}
          type="number"
          inputProps={{ step: "0.01" }}
        />
      </TableCell>
      <TableCell>
        <TextField
          {...getFormAttribute("grossWeight", 0)}
          style={{ width: "8rem" }}
          type="number"
          inputProps={{ step: "0.01" }}
        />
      </TableCell>
      <TableCell>
        <TextField
          {...getFormAttribute("totalPackingList")}
          style={{ width: "8rem" }}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => onDeleteButtonClick(index)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default InvoiceExportRow
