import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import { CircularProgress, makeStyles } from "@material-ui/core"
import MUIDataTable from "mui-datatables"

const useStyles = makeStyles({
  root: {
    "&": {
      position: "relative",
      "&.clickable.Sst-table-has-data": {
        // Override MuiTableRow directly from its classname.
        "& tbody .MuiTableRow-root:not(.ctn-loading)": {
          cursor: "pointer",
        },
      },
    },
  },
  infoContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // NOTE: z-index = 101 because MUIDataTable table header has z-index of 100
    zIndex: 101,
  },
  infoError: {
    boxShadow: "0 8px 8px rgba(0,0,0,0.4)",
    background: "rgba(255, 255, 255, 0.6)",
    padding: "2rem",
    borderRadius: "4px",
  },
})

const setFilterChipProps = () => {
  return {
    color: "primary",
    variant: "outlined",
  }
}

const BaseTableNew = ({
  loading,
  options: parentOptions,
  error,
  errorText,
  className: parentClassName,
  ...otherProps
} = {}) => {
  const classes = useStyles()
  const { textLabels: parentTextLabels = {} } = parentOptions
  const { body: parentTextLabelBody = {} } = parentTextLabels
  const { noMatch: noMatchLabel = "No match found." } = parentTextLabelBody

  const [displayedDataCount, setDisplayedDataCount] = useState(
    otherProps.data?.length
  )

  const onTableChange = (action, tableState) => {
    setDisplayedDataCount(tableState.displayData.length)
    if (parentOptions.onTableChange) {
      parentOptions.onTableChange(action, tableState)
    }
  }

  const onTableInit = (action, tableState) => {
    setDisplayedDataCount(tableState.displayData.length)
    if (parentOptions.onTableInit) {
      parentOptions.onTableInit(action, tableState)
    }
  }

  const isEmpty = displayedDataCount <= 0
  let infoComponent
  if (loading) {
    infoComponent = <CircularProgress />
  }
  if (error) {
    infoComponent = <div className={classes.infoError}>{errorText}</div>
  }

  const options = {
    selectableRows: "none",
    download: false,
    print: false,
    filter: false,
    filterType: "multiselect",
    responsive: "standard",

    ...parentOptions,

    setFilterChipProps,
    onTableChange,
    onTableInit,
    textLabels: {
      ...parentTextLabels,
      body: {
        ...parentTextLabelBody,
        noMatch: (isEmpty && infoComponent) || noMatchLabel,
      },
    },
  }

  const clickable = !!parentOptions.onRowClick

  return (
    <div
      className={clsx(
        parentClassName,
        classes.root,
        !isEmpty && "Sst-table-has-data",
        clickable && "clickable"
      )}
    >
      <MUIDataTable {...otherProps} options={options} />
      {infoComponent && !isEmpty && (
        <div className={classes.infoContainer}>{infoComponent}</div>
      )}
    </div>
  )
}

BaseTableNew.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.shape({}),
  error: PropTypes.bool,
  errorText: PropTypes.node,
}

BaseTableNew.defaultProps = {
  loading: null,
  options: {},
  error: null,
  errorText: "Cannot fetch data.",
  className: null,
}

export default BaseTableNew
