import PropTypes from "prop-types"
import React, { Suspense } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import environment from "utils/environment"

import {
  ROUTE_HOMEPAGE,
  ROUTE_CUSTOMER_ROOT,
  ROUTE_USER_ROOT,
  ROUTE_LOGIN,
  ROUTE_MASTER_DATA_PROCESS,
  ROUTE_ROLE_ROOT,
  ROUTE_MASTER_DATA_MATERIAL,
  ROUTE_PAYMENT_METHODS_ROOT,
  ROUTE_SALES_QUOT_ROOT,
  ROUTE_CURRENCY_ROOT,
  ROUTE_ESTQUOT_ROOT,
  ROUTE_VENDOR_ROOT,
  ROUTE_BILL_OF_MATERIAL_ROOT,
  ROUTE_PURCHASE_ORDER_ROOT,
  ROUTE_GOODS_MOVEMENT_ROOT,
  ROUTE_PROJECTS,
  ROUTE_WEEKLY_TIME_REPORT,
  ROUTE_MASTER_DATA_MACHINE,
  ROUTE_INCOMING_MATERIAL,
  ROUTE_COMPANY_INFORMATION_ROOT,
  ROUTE_PURCHASE_REQUISITION_ROOT,
  ROUTE_DELIVERY_NOTE,
  ROUTE_MONTHLY_TIME_REPORT,
  ROUTE_STOCK_ADJUSTMENT,
  ROUTE_ANNUAL_TIME_REPORT,
  ROUTE_OUTGOING_MATERIAL,
  ROUTE_STOCK_MONITORING,
  ROUTE_MATERIAL_TRANSACTION,
  ROUTE_REQUEST_FOR_QUOTATION,
  ROUTE_DAILY_TIME_REPORT,
  ROUTE_WORK_ORDER,
  ROUTE_MASTER_DATA_MATERIAL_TYPE,
  ROUTE_CUT_OFF,
  ROUTE_SUMMARY_DAILY_TIME_REPORT,
  ROUTE_SUMMARY_DAILY_TIME_REPORT_PROJECT,
  ROUTE_SECTION_ROOT,
  ROUTE_INVOICE_ROOT,
  ROUTE_GOODS_RECEIPT_ROOT,
  ROUTE_GOODS_ISSUE_ROOT,
  ROUTE_REPORT_AP,
  ROUTE_REPORT_AR,
  ROUTE_REPORT_WTR_MONTHLY,
  ROUTE_REPORT_WTR_OPERATOR,
  ROUTE_REPORT_PRODUCT_CHECK_BY_QC,
  ROUTE_REPORT_MOULD_CODE,
  ROUTE_REPORT_PURCHASING,
  ROUTE_REPORT_SQ_OUTSTANDING,
  ROUTE_REPORT_MARKETING_PROJECT,
  ROUTE_DELIVERY_NOTE_SUPPLIER,
  ROUTE_REPORT_WTR_MACHINE,
  ROUTE_PO_CUSTOMER_REPORT,
  ROUTE_PROJECT_PROFIT_REPORT,
  ROUTE_PROJECT_HOUR_REPORT,
  ROUTE_PURCHASING_USAGE_REPORT,
  ROUTE_INVOICE_SUPPLIER_ROOT,
  ROUTE_COVER_WORKING_TIME_REPORT,
  ROUTE_GOODS_RECEIPT_TRACKING_REPORT,
  ROUTE_SALES_REPORT,
  ROUTE_MANUFACTURING_PROGRESS_REPORT,
  ROUTE_MANUFACTURING_PLANNING,
  ROUTE_MANUFACTURING_PLANNING_REPORT,
  ROUTE_MANUFACTURING_PLANNING_REPORT_ITEM,
  ROUTE_BILL_OF_MATERIAL_COST_REPORT,
  ROUTE_MANUFACTURING_PROCESS_ROOT,
  ROUTE_COMPILATION_PROJECT_PROFIT,
} from "constants/routeConstants"
import RouteBreadcrumb from "components/page-layout/breadcrumbs/RouteBreadcrumb"
import WorkOrderListPage from "pages/work-order/WorkOrderListPage"
import InvoiceListPage from "pages/invoice/InvoiceListPage"

const HomePage = React.lazy(() => import("pages/home"))
const CustomerListPage = React.lazy(() =>
  import("pages/customer/CustomerListPage")
)
const CompanyInformationPage = React.lazy(() =>
  import("pages/company-information/CompanyInformationItemPage")
)
const UserListPage = React.lazy(() => import("pages/user/UserListPage"))
const MasterDataProcessListPage = React.lazy(() =>
  import("pages/master-data-process/ProcessListPage")
)
const MasterDataMaterialListPage = React.lazy(() =>
  import("pages/master-data-material/MaterialListPage")
)
const RoleListPage = React.lazy(() => import("pages/role/RoleListPage"))
const PaymentMethodListPage = React.lazy(() =>
  import("pages/payment-method/PaymentMethodListPage")
)
const CurrencyListPage = React.lazy(() =>
  import("pages/currency/CurrencyListPage")
)
const SalesQuotationListPage = React.lazy(() =>
  import("pages/sales-quotation/SalesQuotationListPage")
)
const MasterDataMachineListPage = React.lazy(() =>
  import("pages/master-data-machine/MachineListPage")
)
const QuotationEstimatesListPage = React.lazy(() =>
  import("pages/quotation-estimates/QuotationEstimatesListPage")
)
const VendorListPage = React.lazy(() => import("pages/vendor/VendorListPage"))
const BillOfMaterialListPage = React.lazy(() =>
  import("pages/bill-of-material/BillOfMaterialListPage")
)
const PurchaseOrderListPage = React.lazy(() =>
  import("pages/purchase-order/PurchaseOrderListPage")
)
const GoodsMovementCreatePage = React.lazy(() =>
  import("pages/goods-movement/GoodsMovementCreatePage")
)
const ProjectListPage = React.lazy(() =>
  import("pages/projects/ProjectListPage")
)
const IncomingMaterialListPage = React.lazy(() =>
  import("pages/incoming-material/IncomingMaterialListPage")
)
const OutgoingMaterialEntryPage = React.lazy(() =>
  import("pages/outgoing-material/OutgoingMaterialEntryPage")
)

const WeeklyReportEntryPage = React.lazy(() =>
  import("pages/work-time-report/weekly/WeeklyReportEntryPage")
)

const PurchaseRequisitionListPage = React.lazy(() =>
  import("pages/purchase-requisition/PurchaseRequisitionListPage")
)
const DeliveryNoteListPage = React.lazy(() =>
  import("pages/delivery-note/DeliveryNoteListPage")
)

const DailyReportEntryPage = React.lazy(() =>
  import("pages/work-time-report/daily/DailyReportEntryPage")
)
const MonthlyWTRListPage = React.lazy(() =>
  import("pages/work-time-report/monthly/MonthlyWTRListPage")
)
const StockAdjustmentListPage = React.lazy(() =>
  import("pages/stock-adjustment/StockAdjustmentListPage")
)
const AnnualReportEntryPage = React.lazy(() =>
  import("pages/work-time-report/annual/AnnualReportEntryPage")
)
const StockMonitoringListPage = React.lazy(() =>
  import("pages/report-stock-monitoring/StockMonitoringListPage")
)
const MaterialTransactionListPage = React.lazy(() =>
  import("pages/material-transaction/MaterialTransactionListPage")
)
const RequestForQuotationListPage = React.lazy(() =>
  import("pages/request-for-quotation/RfqEntryPage")
)
const MaterialTypeListPage = React.lazy(() =>
  import("pages/master-data-material-type/MaterialTypeListPage")
)
const CutOffStockListPage = React.lazy(() =>
  import("pages/cut-off/CutOffListPage")
)
const DailySummaryReportPage = React.lazy(() =>
  import("pages/work-time-report/summary/DailySummaryEntryPage")
)
const DailySummaryProjectPage = React.lazy(() =>
  import("pages/work-time-report/summary/project/DailySummaryProjectEntryPage")
)
const SectionListPage = React.lazy(() =>
  import("pages/section/SectionListPage")
)
const ProjectCostPage = React.lazy(() =>
  import("pages/projects/ProjectCostPage")
)
const ProjectHourPage = React.lazy(() =>
  import("pages/projects/ProjectHourPage")
)
const GoodsReceiptListPage = React.lazy(() =>
  import("pages/goods-movement/receipt/GoodsReceipListPage")
)
const GoodsIssueListPage = React.lazy(() =>
  import("pages/goods-movement/issue/GoodsIssueListPage")
)
const AccountPayablePage = React.lazy(() =>
  import("pages/report/AccountPayablePage")
)
const AccountReceivablePage = React.lazy(() =>
  import("pages/report/AccountReceivablePage")
)
const WTRMonthlyPage = React.lazy(() =>
  import("pages/report/WorkingTimeReportMonthlyPage")
)
const WTROperatorPage = React.lazy(() =>
  import("pages/report/WorkingTimeReportOperator")
)
const WTRMachinePage = React.lazy(() =>
  import("pages/report/WorkingTimeReportMachine")
)
const ProductCheckedByQcPage = React.lazy(() =>
  import("pages/report/ProductCheckedByQc")
)
const MouldCodePage = React.lazy(() => import("pages/report/MouldCodePage"))
const PurchasingReportPage = React.lazy(() =>
  import("pages/report/PurchasingReportPage")
)
const SQOutstandingPage = React.lazy(() =>
  import("pages/report/SQOutstandingPage")
)
const MarketingProjectPage = React.lazy(() =>
  import("pages/report/MarketingProjectPage")
)
const DeliveryNoteSupplierListPage = React.lazy(() =>
  import("pages/delivery-notes-supplier/DeliveryNoteSupplierListPage")
)
const POCustomerPage = React.lazy(() => import("pages/report/POCustomerPage"))
const ProjectProfitListPage = React.lazy(() =>
  import("pages/report/ProjectProfitListPage")
)
const ProjectHourListPage = React.lazy(() =>
  import("pages/report/ProjectHourListPage")
)
const PurchasingUsagePage = React.lazy(() =>
  import("pages/report/PurchasingUsageReportPage")
)
const InvoiceSupplierListPage = React.lazy(() =>
  import("pages/invoice-supplier/InvoiceSupplierListPage")
)
const CoverWTControlPage = React.lazy(() =>
  import("pages/report/WTControlPage")
)
const GoodsReceiptTrackingPage = React.lazy(() =>
  import("pages/report/GoodsReceiptTrackingPage")
)
const ManufacturingListPage = React.lazy(() =>
  import("pages/manufacturing-progress/ManufacturingProgressListPage")
)
const InvoiceSalesListPage = React.lazy(() =>
  import("pages/invoice/InvoiceSalesListPage")
)
const ManufacturingProgressReportPage = React.lazy(() =>
  import("pages/manufacturing-progress/ManufacturingProgressReportPage")
)
const ManufacturingPlanListPage = React.lazy(() =>
  import("pages/manufacturing-planning/ManufacturingPlanListPage")
)
const ManufacturingPlanReportPage = React.lazy(() =>
  import("pages/manufacturing-planning/ManufacturingPlanReportPage")
)
const ManufacturingPlanReportItemPage = React.lazy(() =>
  import("pages/manufacturing-plan-item/ManufacturingPlanReportItemPage")
)
const BillOfMaterialCostListPage = React.lazy(() =>
  import("pages/bill-of-material/BillOfMaterialCostListPage")
)
const ManufacturingProcessListPage = React.lazy(() =>
  import("pages/manufacturing-process/ManufacturingProcessListPage")
)
const ProjectProfitCompilationListPage = React.lazy(() =>
  import("pages/report/CompilationProjectProfit")
)
const TruckUtilityListPage = React.lazy(() =>
  import("pages/truck-utility/TruckUtilityListPage")
)

const ProtectedRoute = ({ authenticated }) => {
  if (!authenticated && !environment.loginDisable) {
    return <Redirect to={ROUTE_LOGIN} />
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path={ROUTE_HOMEPAGE} component={HomePage} />
        <RouteBreadcrumb
          exact
          name="Bill Of Material Cost"
          path={ROUTE_BILL_OF_MATERIAL_COST_REPORT}
          component={BillOfMaterialCostListPage}
        />
        <RouteBreadcrumb
          name="Account Payable"
          exact
          path={ROUTE_REPORT_AP}
          component={AccountPayablePage}
        />
        <RouteBreadcrumb
          name="Account Receivable"
          exact
          path={ROUTE_REPORT_AR}
          component={AccountReceivablePage}
        />
        <RouteBreadcrumb
          name="Working Time Report (monthly)"
          exact
          path={ROUTE_REPORT_WTR_MONTHLY}
          component={WTRMonthlyPage}
        />
        <RouteBreadcrumb
          name="Working Time Report (operator)"
          exact
          path={ROUTE_REPORT_WTR_OPERATOR}
          component={WTROperatorPage}
        />
        <RouteBreadcrumb
          name="Working Time Report (machine)"
          exact
          path={ROUTE_REPORT_WTR_MACHINE}
          component={WTRMachinePage}
        />
        <RouteBreadcrumb
          name="Cover WT Control"
          exact
          component={CoverWTControlPage}
          path={ROUTE_COVER_WORKING_TIME_REPORT}
        />
        <RouteBreadcrumb
          name="Product Checked by Qc"
          exact
          path={ROUTE_REPORT_PRODUCT_CHECK_BY_QC}
          component={ProductCheckedByQcPage}
        />
        <RouteBreadcrumb
          name="Mould Code"
          exact
          path={ROUTE_REPORT_MOULD_CODE}
          component={MouldCodePage}
        />
        <RouteBreadcrumb
          name="Purchasing Report"
          exact
          path={ROUTE_REPORT_PURCHASING}
          component={PurchasingReportPage}
        />
        <RouteBreadcrumb
          name="SQ PO Outstanding"
          exact
          path={ROUTE_REPORT_SQ_OUTSTANDING}
          component={SQOutstandingPage}
        />
        <RouteBreadcrumb
          name="Invoice Sales"
          exact
          path={ROUTE_SALES_REPORT}
          component={InvoiceSalesListPage}
        />
        <RouteBreadcrumb
          name="Sales Report"
          exact
          path={ROUTE_REPORT_MARKETING_PROJECT}
          component={MarketingProjectPage}
        />
        <RouteBreadcrumb
          name="Manufacturing Progress Report"
          exact
          path={ROUTE_MANUFACTURING_PROGRESS_REPORT}
          component={ManufacturingProgressReportPage}
        />
        <RouteBreadcrumb
          name="Manufacturing Planning Report"
          exact
          path={ROUTE_MANUFACTURING_PLANNING_REPORT}
          component={ManufacturingPlanReportPage}
        />
        <RouteBreadcrumb
          name="Manufacturing Planning Report Item"
          exact
          path={ROUTE_MANUFACTURING_PLANNING_REPORT_ITEM}
          component={ManufacturingPlanReportItemPage}
        />
        <RouteBreadcrumb
          name="PO Customer"
          exact
          path={ROUTE_PO_CUSTOMER_REPORT}
          component={POCustomerPage}
        />
        <RouteBreadcrumb
          name="Project Profit"
          exact
          path={ROUTE_PROJECT_PROFIT_REPORT}
          component={ProjectProfitListPage}
        />
        <RouteBreadcrumb
          name="Project Hour"
          exact
          path={ROUTE_PROJECT_HOUR_REPORT}
          component={ProjectHourListPage}
        />
        <RouteBreadcrumb
          name="Purchasing Usage"
          exact
          path={ROUTE_PURCHASING_USAGE_REPORT}
          component={PurchasingUsagePage}
        />
        <RouteBreadcrumb
          name="Goods Receipt Tracking"
          exact
          path={ROUTE_GOODS_RECEIPT_TRACKING_REPORT}
          component={GoodsReceiptTrackingPage}
        />
        <RouteBreadcrumb
          path={ROUTE_COMPANY_INFORMATION_ROOT}
          component={CompanyInformationPage}
          name="Company Information"
        />
        <RouteBreadcrumb
          name="Manufacturing Planning"
          path={ROUTE_MANUFACTURING_PLANNING}
          component={ManufacturingPlanListPage}
        />
        <RouteBreadcrumb
          path={ROUTE_CUSTOMER_ROOT}
          component={CustomerListPage}
          name="List Customer"
        />
        <RouteBreadcrumb
          path={ROUTE_COMPILATION_PROJECT_PROFIT}
          component={ProjectProfitCompilationListPage}
          name="Compilation of Project Profit"
        />
        <RouteBreadcrumb
          path={ROUTE_USER_ROOT}
          component={UserListPage}
          name="List User"
        />
        <RouteBreadcrumb
          path={ROUTE_MANUFACTURING_PROCESS_ROOT}
          component={ManufacturingProcessListPage}
          name="List Manufacturing Process"
        />
        <RouteBreadcrumb
          path={ROUTE_ROLE_ROOT}
          component={RoleListPage}
          name="List Role"
        />
        <RouteBreadcrumb
          path={ROUTE_ESTQUOT_ROOT}
          component={QuotationEstimatesListPage}
          name="List Estimate Quotation"
        />
        <RouteBreadcrumb
          path={ROUTE_PAYMENT_METHODS_ROOT}
          component={PaymentMethodListPage}
          name="List Payment Method"
        />
        <RouteBreadcrumb
          path={ROUTE_CURRENCY_ROOT}
          component={CurrencyListPage}
          name="List Currency"
        />
        <RouteBreadcrumb
          path={ROUTE_MASTER_DATA_PROCESS}
          component={MasterDataProcessListPage}
          name="List Process"
        />
        <RouteBreadcrumb
          path={ROUTE_MASTER_DATA_MATERIAL}
          component={MasterDataMaterialListPage}
          name="List Material"
        />
        <RouteBreadcrumb
          path={ROUTE_MASTER_DATA_MACHINE}
          component={MasterDataMachineListPage}
          name="List Machine"
        />
        <RouteBreadcrumb
          path={ROUTE_SALES_QUOT_ROOT}
          component={SalesQuotationListPage}
          name="List Sales Quotation"
        />
        <RouteBreadcrumb
          path={ROUTE_VENDOR_ROOT}
          component={VendorListPage}
          name="List Vendor"
        />
        <RouteBreadcrumb
          path={ROUTE_BILL_OF_MATERIAL_ROOT}
          component={BillOfMaterialListPage}
          name="List Bill of Material"
        />

        <RouteBreadcrumb
          path={ROUTE_PURCHASE_ORDER_ROOT}
          component={PurchaseOrderListPage}
          name="List Purchase Order"
        />
        <RouteBreadcrumb
          path={ROUTE_GOODS_MOVEMENT_ROOT}
          component={GoodsMovementCreatePage}
          name="Create Goods Movement"
        />
        <RouteBreadcrumb
          path={ROUTE_GOODS_RECEIPT_ROOT}
          component={GoodsReceiptListPage}
          name="List Goods Receipt"
        />
        <RouteBreadcrumb
          path={ROUTE_GOODS_ISSUE_ROOT}
          component={GoodsIssueListPage}
          name="List Goods Issue"
        />
        <RouteBreadcrumb
          path={ROUTE_INCOMING_MATERIAL}
          component={IncomingMaterialListPage}
          name="List Incoming Material"
        />
        <Route
          path={ROUTE_DAILY_TIME_REPORT}
          component={DailyReportEntryPage}
          // name="List Daily Production Report"
        />
        <RouteBreadcrumb
          path={ROUTE_SUMMARY_DAILY_TIME_REPORT}
          component={DailySummaryReportPage}
          name="List Daily Summary Production Report (Machine)"
        />
        <RouteBreadcrumb
          path={ROUTE_SUMMARY_DAILY_TIME_REPORT_PROJECT}
          component={DailySummaryProjectPage}
          name="List Daily Summary Production Report (Project)"
        />
        <RouteBreadcrumb
          path={ROUTE_WEEKLY_TIME_REPORT}
          component={WeeklyReportEntryPage}
          name="List Weekly Work Time Report"
        />
        <RouteBreadcrumb
          path={ROUTE_MONTHLY_TIME_REPORT}
          component={MonthlyWTRListPage}
          name="Monthly Report"
        />
        <RouteBreadcrumb
          name="Project List"
          path={ROUTE_PROJECTS}
          component={ProjectListPage}
        />
        <RouteBreadcrumb
          path={ROUTE_PURCHASE_REQUISITION_ROOT}
          component={PurchaseRequisitionListPage}
          name="Purchase Requisition List"
        />
        <RouteBreadcrumb
          path={ROUTE_SECTION_ROOT}
          component={SectionListPage}
          name="Section List"
        />
        <RouteBreadcrumb
          path={ROUTE_MONTHLY_TIME_REPORT}
          component={MonthlyWTRListPage}
          name="Monthly Report"
        />
        <RouteBreadcrumb
          path={ROUTE_STOCK_ADJUSTMENT}
          component={StockAdjustmentListPage}
          name="Stock Adjustment"
        />
        <RouteBreadcrumb
          path={ROUTE_DELIVERY_NOTE}
          component={DeliveryNoteListPage}
          name="Delivery Note List"
        />
        <RouteBreadcrumb
          path={ROUTE_DELIVERY_NOTE_SUPPLIER}
          component={DeliveryNoteSupplierListPage}
          name="Delivery Note Supplier List"
        />
        <RouteBreadcrumb
          path={ROUTE_ANNUAL_TIME_REPORT}
          component={AnnualReportEntryPage}
          name="Annual Report"
        />
        <RouteBreadcrumb
          path={ROUTE_OUTGOING_MATERIAL}
          component={OutgoingMaterialEntryPage}
          name="Outgoing Mould QC"
        />
        <RouteBreadcrumb
          path={ROUTE_STOCK_MONITORING}
          component={StockMonitoringListPage}
          name="Report Stock Monitoring & Control"
        />
        <RouteBreadcrumb
          path={ROUTE_MATERIAL_TRANSACTION}
          component={MaterialTransactionListPage}
          name="Material Transaction Table"
        />
        <RouteBreadcrumb
          path={ROUTE_REQUEST_FOR_QUOTATION}
          component={RequestForQuotationListPage}
          name="Request For Quotation"
        />
        <RouteBreadcrumb
          path={ROUTE_WORK_ORDER}
          component={WorkOrderListPage}
          name="Work Order"
        />
        <RouteBreadcrumb
          path={ROUTE_MASTER_DATA_MATERIAL_TYPE}
          component={MaterialTypeListPage}
          name="Material Type"
        />
        <RouteBreadcrumb
          path={ROUTE_CUT_OFF}
          component={CutOffStockListPage}
          name="Cut Off Stock"
        />
        <RouteBreadcrumb
          path={ROUTE_INVOICE_ROOT}
          component={InvoiceListPage}
          name="Invoice"
        />
        <RouteBreadcrumb
          path={ROUTE_INVOICE_SUPPLIER_ROOT}
          component={InvoiceSupplierListPage}
          name="Invoice Supplier"
        />
        <RouteBreadcrumb
          path="/project-cost"
          component={ProjectCostPage}
          name="Project Cost"
        />
        <RouteBreadcrumb
          path="/project-hour"
          name="Project Hour"
          component={ProjectHourPage}
        />
        <RouteBreadcrumb
          path="/manufacturing-progress"
          name="Manufacturing Progress"
          component={ManufacturingListPage}
        />
        <RouteBreadcrumb
          path="/truck-utility"
          name="Truck Utility"
          component={TruckUtilityListPage}
        />
        <Route path="/">
          Page Not Found
          <br />
          We can’t find the page you’re looking for.
        </Route>
      </Switch>
    </Suspense>
  )
}

ProtectedRoute.propTypes = {
  authenticated: PropTypes.bool,
}

ProtectedRoute.defaultProps = {
  authenticated: null,
}

export default ProtectedRoute
