import { axios, ENDPOINT } from "./constants"
import { handleSilentApiError } from "./utils"

export default class ApiUploadFile {
  static async create(payload, onUploadProgress) {
    const formData = new FormData()
    formData.append("fileBox", payload)
    formData.append("query", "mutation{ uploadImageCenter { fileBox: fileBox}}")
    const response = await axios.post(ENDPOINT.CREATE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    })
    handleSilentApiError(response)
    return { data: response.data.data.uploadImageCenter }
  }

  static async uploadFile(file, onUploadProgress) {
    const formData = new FormData()
    formData.append("fileBox", file)
    formData.append(
      "query",
      "mutation { uploadFileCenter { fileBox: fileBox } }"
    )
    const response = await axios.post(ENDPOINT.CREATE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    })
    handleSilentApiError(response)
    return { data: response.data.data.uploadFileCenter }
  }
}
