import React from "react"
import PropTypes from "prop-types"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import defaultTheme from "app-theme"

import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  grid: {
    fontSize: theme.typography.body2.fontSize,
    "& .label": {
      fontWeight: "bold",
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    "& .description": {
      paddingTop: "8px",
      paddingBottom: "8px",
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
    },
  },
  headerTitle: {
    fontSize: theme.typography.h6.fontSize,
    margin: 0,
  },
  headerTitleContainer: {
    flexGrow: "1",
    marginTop: "8px",
    marginBottom: "8px",
    wordWrap: "break-word",
    width: "50%",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  buttonDelete: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  buttonEdit: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  buttonIcon: {
    marginLeft: "-5px",
    marginRight: "5px",
  },
}))

const ItemDisplay = ({
  title,
  data,
  onConfirmEdit,
  onConfirmDelete,
  attrsAndLabels,
  showDeleteButton,
  showEditButton,
  renderData,
  xsL,
  smL,
  mdL,
  lgL,
  xlL,
}) => {
  const classes = useStyles(defaultTheme)

  const editButton = (
    <Button
      className={classes.buttonEdit}
      variant="contained"
      color="primary"
      disableElevation
      onClick={onConfirmEdit}
    >
      <EditIcon className={classes.buttonIcon} fontSize="small" /> Edit
    </Button>
  )
  const deleteButton = (
    <Button
      className={classes.buttonDelete}
      variant="outlined"
      color="secondary"
      disableElevation
      onClick={onConfirmDelete}
    >
      <DeleteIcon className={classes.buttonIcon} fontSize="small" /> Delete
    </Button>
  )

  const headerTitle =
    typeof title === "string" ? (
      <h3 className={classes.headerTitle}>{title}</h3>
    ) : (
      title
    )
  const titleExists =
    typeof title === "string" ? title.trim().length > 0 : !!title
  const hideHeader = !titleExists && !showDeleteButton && !showEditButton
  const showHeader = !hideHeader

  const xsR = typeof xsL === "number" ? 12 - xsL : null
  const smR = typeof smL === "number" ? 12 - smL : null
  const mdR = typeof mdL === "number" ? 12 - mdL : null
  const lgR = typeof lgL === "number" ? 12 - lgL : null
  const xlR = typeof xlL === "number" ? 12 - xlL : null

  return (
    <div>
      {showHeader ? (
        <div className={classes.headerContainer}>
          <div className={classes.headerTitleContainer}>{headerTitle}</div>
          <div>
            {showEditButton ? editButton : null}
            {showDeleteButton ? deleteButton : null}
          </div>
        </div>
      ) : null}
      <Grid container spacing={3} className={classes.grid}>
        {attrsAndLabels.map(({ attr, label, display }) => (
          <React.Fragment key={attr}>
            <Grid
              item
              xs={xsL}
              sm={smL}
              md={mdL}
              lg={lgL}
              xl={xlL}
              className="label"
            >
              {label}
            </Grid>
            <Grid
              item
              xs={xsR}
              sm={smR}
              md={mdR}
              lg={lgR}
              xl={xlR}
              className="description"
            >
              {display ? display(data[attr], renderData, attr) : data[attr]}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </div>
  )
}

ItemDisplay.propTypes = {
  title: PropTypes.node,
  data: PropTypes.shape({}).isRequired,
  onConfirmEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  attrsAndLabels: PropTypes.arrayOf(
    PropTypes.shape({
      attr: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      display: PropTypes.func,
    })
  ),
  renderData: PropTypes.shape({}),
  showDeleteButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  xsL: PropTypes.number,
  smL: PropTypes.number,
  mdL: PropTypes.number,
  lgL: PropTypes.number,
  xlL: PropTypes.number,
}

ItemDisplay.defaultProps = {
  title: "",
  onConfirmEdit: () => {},
  onConfirmDelete: () => {},
  attrsAndLabels: [],
  showDeleteButton: true,
  showEditButton: true,
  renderData: {},
  xsL: 3,
  smL: null,
  mdL: null,
  lgL: null,
  xlL: null,
}

export default ItemDisplay
