/* eslint-disable react/prop-types */
import { yupResolver } from "@hookform/resolvers"
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core"
import { Delete, Edit } from "@material-ui/icons"
import BaseTableNew from "components/common/BaseTableNew"
import DeleteDialog from "components/common/DeleteDialog"

import React, { useMemo } from "react"
import { useForm } from "react-hook-form"
import ApiInvoice from "services/api-invoice"
import { sendAndHandleInvalidToken } from "utils/invalid-token"
import useShowSnackbar from "utils/snackbar-hooks"
import * as yup from "yup"

const schema = yup.object({
  mouldCode: yup.string().required("This field is required"),
  description: yup.string().required("This field is required"),
  poNumber: yup.string().required("This field is required"),
  unit: yup.string().required("This field is required"),
  quantity: yup
    .number()
    .typeError("Must be a number")
    .required("This field is required"),
})

function InvoiceMergeForm({
  disabled,
  defaultValue = [],
  id,
  setFlagRefetch,
  selections,
}) {
  const { setValue, errors, register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  })
  const [currentDetail, setCurrentDetail] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [mode, setMode] = React.useState("")
  const [showSnackbar, showSnackbarLoading, hideSnackbar] = useShowSnackbar()

  const handleMergeInvoice = async (val) => {
    let fetchState = {}
    try {
      showSnackbarLoading("Loading...")

      if (selections.length) {
        fetchState = await sendAndHandleInvalidToken(() =>
          ApiInvoice.mergeInvoiceDetail({
            ...val,
            invoiceDetailId: selections.map((s) => parseInt(s, 10)),
            invoiceId: parseInt(id, 10),
          })
        )
      } else {
        fetchState = await sendAndHandleInvalidToken(() =>
          ApiInvoice.updateMergeInvoiceDetail({
            ...val,
            invoiceDetailId: selections.map((s) => parseInt(s, 10)),
            invoiceId: parseInt(id, 10),
          })
        )
      }
      if (fetchState.success) {
        showSnackbar("Invoice detail merged successfully!")
        setFlagRefetch()
      } else {
        hideSnackbar()
      }
    } catch (error) {
      showSnackbar(error?.errors[0]?.message || "Something went wrong")
    }
  }
  const handleDeleteMergeInvoice = async (invoiceDetailMergeId) => {
    let fetchState = {}
    try {
      showSnackbarLoading("Loading...")

      fetchState = await sendAndHandleInvalidToken(() =>
        ApiInvoice.deleteMergeInvoiceDetail(parseInt(invoiceDetailMergeId, 10))
      )
      if (fetchState.success) {
        showSnackbar("Delete susccess")
        setFlagRefetch()
      } else {
        hideSnackbar()
      }
    } catch (error) {
      showSnackbar("Something went wrong")
    }
  }

  const getFormAttribute = (label, name, inputId) => {
    const errorObj = errors[name]
    return {
      label,
      name,
      id: inputId,
      inputRef: register,
      error: errorObj,
      helperText: errorObj?.message,
      fullWidth: true,
      disabled: disabled && !currentDetail && mode !== "EDIT",
      InputLabelProps: { shrink: true },
    }
  }

  const reset = () => {
    setValue("description", "")
    setValue("mouldCode", "")
    setValue("quantity", 0)
    setValue("unit", "")
    setValue("poNumber", "")
  }

  React.useEffect(() => {
    reset()
    setCurrentDetail(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  React.useEffect(() => {
    if (currentDetail && mode === "EDIT") {
      setValue("description", currentDetail.description)
      setValue("mouldCode", currentDetail.mouldCode)
      setValue("quantity", currentDetail.quantity)
      setValue("unit", currentDetail.unit)
      setValue("poNumber", currentDetail.poNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDetail, mode])

  const submitForm = async (val) => {
    const params = { ...val }
    if (currentDetail) {
      params.id = parseInt(currentDetail.idInvoiceDetailMerge, 10)
    }
    handleMergeInvoice(params)
  }

  const columns = useMemo(() => {
    return [
      { name: "description", label: "Description" },
      { name: "mouldCode", label: "Mould Code" },
      { name: "poNumber", label: "PO Number" },
      { name: "unit", label: "Unit" },
      { name: "quantity", label: "Quantity" },
      {
        name: "",
        label: "",
        options: {
          customBodyRenderLite: (index) => {
            const curr = defaultValue[index]
            return (
              <div style={{ display: "flex", gap: "1rem" }}>
                <IconButton
                  onClick={() => {
                    setCurrentDetail(curr)
                    setMode("EDIT")
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setCurrentDetail(curr)
                    setMode("DELETE")
                    setOpen(true)
                  }}
                >
                  <Delete />
                </IconButton>
              </div>
            )
          },
        },
      },
    ]
  }, [defaultValue])

  const onDialogClose = ({ confirmed }) => {
    setOpen(false)
    if (confirmed) {
      handleDeleteMergeInvoice(currentDetail.idInvoiceDetailMerge)
    }
  }

  return (
    <>
      <Typography variant="h5">Merge Invoice</Typography>
      <BaseTableNew columns={columns} data={defaultValue} />
      <form onSubmit={handleSubmit(submitForm)} style={{ marginTop: "24px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <TextField
              {...getFormAttribute("Description", "description", "description")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              {...getFormAttribute("Mould Code", "mouldCode", "mouldCode")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              {...getFormAttribute("PO Number", "poNumber", "poNumber")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField {...getFormAttribute("Unit", "unit", "unit")} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              {...getFormAttribute("Quantity", "quantity", "quantity")}
              type="number"
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "12px" }}
          disabled={disabled && !currentDetail}
        >
          Submit
        </Button>
      </form>
      <DeleteDialog open={open} onClose={onDialogClose} />
    </>
  )
}

export default InvoiceMergeForm
