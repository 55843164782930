/* eslint-disable react/prop-types */
import { yupResolver } from "@hookform/resolvers"
import { Grid, TextField, Typography } from "@material-ui/core"
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import * as t from "prop-types"
import { Autocomplete } from "@material-ui/lab"

import CustomerDisplay from "components/delivery-note/CustomerDisplays"
import { transformProperty } from "utils/objects"
import { purgeInitialFormData } from "utils/form-data"
import InvoiceFormContext from "./InvoiceFormContex"
import { getDefaultSettings } from "./utils"
import { CUSTOMER_BLANK_FORMS } from "./const"

const schema = yup.object().shape({
  customerId: yup.number().required(),
  address: yup.string().required(),
  phone: yup.string().required(),
  attn: yup.string().required(),
  currencyId: yup.string().required("This field is required"),
  currencyRate: yup.number().min(1),
  rateInId: yup.string().when("$invoiceType", (invoiceType, s) => {
    if (invoiceType === "DEBIT NOTE") {
      return s
    }
    return s.required("This field is required")
  }),
})

const InvoiceCustomerForm = forwardRef(
  ({ fetchCurrencyData, fetchCustomerData, initialValue }, ref) => {
    const [initialFormData] = useState(() =>
      purgeInitialFormData(initialValue, CUSTOMER_BLANK_FORMS)
    )
    const [address, setAddress] = useState(initialValue.address)

    const {
      selectedCustomer,
      setSelectedCustomer,
      selectedCurrency,
      setSelectedCurrency,
      invoiceType,
      currencyRate,
      setCurrencyRate,
      rateIn,
      setRateIn,
    } = useContext(InvoiceFormContext)

    const destinationList = React.useMemo(() => {
      return selectedCustomer?.destinationList || []
    }, [selectedCustomer])

    const {
      register,
      errors,
      setValue,
      getValues,
      control,
      handleSubmit,
    } = useForm({
      defaultValues: initialFormData,
      resolver: yupResolver(schema),
      context: { invoiceType },
    })

    const [currencies, setCurrencies] = React.useState([])
    const [customers, setCustomers] = React.useState([])

    useImperativeHandle(ref, () => ({
      getFormData: () => {
        const data = getValues()
        transformProperty(
          data,
          ["customerId", "currencyId", "currencyRate", "rateInId"],
          parseInt
        )
        if (!data.rateInId) delete data.rateInId
        return data
      },
      submitForm: async () => {
        return new Promise((res, rej) => handleSubmit(res, rej)())
      },
    }))

    React.useEffect(() => {
      let active = true

      const fetchData = async () => {
        const response = await fetchCustomerData()

        if (!active) return

        setCustomers(response)
      }

      fetchData()

      return () => {
        active = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
      let active = true

      const fetchData = async () => {
        const response = await fetchCurrencyData()

        if (!active) return

        setCurrencies(response)
      }

      fetchData()

      return () => {
        active = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
      const current = currencies.filter(
        (item) => item.idCurr === initialFormData.currencyId
      )[0]
      if (current) {
        setSelectedCurrency(current)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencies, initialFormData])

    const firstRenderCustomer = useRef(1)

    React.useLayoutEffect(() => {
      if (firstRenderCustomer.current !== 1) {
        setValue("attn", selectedCustomer?.pic)
        setValue("phone", selectedCustomer?.phoneNumber)
      }
      firstRenderCustomer.current += 1
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer])

    return (
      <Grid item md={6}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Customer Data
        </Typography>

        <Autocomplete
          options={customers}
          getOptionLabel={(opt) => (opt ? `${opt.custCode} (${opt.name})` : "")}
          renderOption={(opt) => <CustomerDisplay customerData={opt} />}
          value={selectedCustomer}
          getOptionSelected={(opt, val) => {
            return opt.idCust === val?.idCust
          }}
          onChange={(e, val) => {
            setSelectedCustomer(val)
          }}
          renderInput={(params) => (
            <TextField
              label="Customer Name"
              {...params}
              fullWidth
              margin="dense"
              error={!!errors?.customerId}
              helperText={errors?.customerId?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="address"
          defaultValue={initialFormData.address || ""}
          render={({ onChange }) => {
            return (
              <Autocomplete
                options={destinationList}
                getOptionSelected={(opt, val) => opt.destination === val}
                value={address}
                getOptionLabel={(opt) => opt?.destination || opt}
                renderOption={(opt) => {
                  return opt?.destination || opt
                }}
                onChange={(e, val) => {
                  onChange(val?.destination)
                  setAddress(val)
                }}
                renderInput={(params) => (
                  <TextField
                    label="Address"
                    {...params}
                    fullWidth
                    margin="dense"
                    error={!!errors?.address}
                    helperText={errors?.address?.message}
                  />
                )}
              />
            )
          }}
        />

        <input
          type="hidden"
          name="customerId"
          ref={register}
          value={selectedCustomer?.idCust ?? ""}
        />

        <TextField
          {...getDefaultSettings("phone", "Phone", register, errors)}
          defaultValue={initialFormData.phone}
        />

        <TextField
          {...getDefaultSettings("attn", "Attn", register, errors)}
          defaultValue={initialFormData.attn}
        />

        {invoiceType !== "DEBIT NOTE" && (
          <Controller
            control={control}
            name="rateInId"
            defaultValue={initialFormData.rateInId ?? ""}
            render={({ onChange }) => {
              return (
                <Autocomplete
                  options={currencies}
                  getOptionLabel={(option) => (option ? option.code : "")}
                  getOptionSelected={(option, val) => {
                    return option.idCurr === val?.idCurr
                  }}
                  value={rateIn}
                  onChange={(e, val) => {
                    setRateIn(val)
                    onChange(val?.idCurr)
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label="Rate In"
                        {...params}
                        margin="dense"
                        fullWidth
                        error={!!errors?.currencyId}
                        helperText={errors?.currencyId?.message}
                      />
                    )
                  }}
                />
              )
            }}
          />
        )}

        <Controller
          control={control}
          name="currencyId"
          defaultValue={initialFormData.currencyId || ""}
          render={({ onChange }) => {
            return (
              <Autocomplete
                options={currencies}
                getOptionLabel={(option) => (option ? option.code : "")}
                getOptionSelected={(option, val) => {
                  return option.idCurr === val?.idCurr
                }}
                value={selectedCurrency || ""}
                onChange={(e, val) => {
                  setSelectedCurrency(val)
                  onChange(val?.idCurr)
                  setValue("currencyRate", val?.value ?? 1)
                  setCurrencyRate(val?.value ?? 1)
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      label="Rate Out"
                      {...params}
                      margin="dense"
                      fullWidth
                      error={!!errors?.currencyId}
                      helperText={errors?.currencyId?.message}
                    />
                  )
                }}
              />
            )
          }}
        />

        {invoiceType !== "DEBIT NOTE" && (
          <TextField
            {...getDefaultSettings(
              "currencyRate",
              "Currency Rate",
              register,
              errors
            )}
            type="number"
            inputProps={{
              step: "0.0000001",
            }}
            defaultValue={currencyRate || ""}
            onChange={(e) => {
              setValue("currencyRate", e.target.value)
              setCurrencyRate(e.target.value)
            }}
          />
        )}
      </Grid>
    )
  }
)

InvoiceCustomerForm.propTypes = {
  fetchCurrencyData: t.func.isRequired,
  fetchCustomerData: t.func.isRequired,
}

export default InvoiceCustomerForm
