import { makeStyles } from "@material-ui/core"

export const GENERAL_BLANK_FORMS = {
  date: "",
  invoiceType: "INVOICE",
  payment: "",
  source: "DELIVERY NOTE",
  paymentPercentage: 100,
  authorizedSignatureId: null,
  pph23: 0,
  remarks: "",
  paymentTerms: "",
  notes: "",
}

export const CUSTOMER_BLANK_FORMS = {
  customerId: 0,
  address: " ",
  rateInId: null,
  phone: " ",
  attn: " ",
  currencyId: "",
  currencyRate: 1,
}

export const INVOICE_BLANK_FORM = {
  ...GENERAL_BLANK_FORMS,
  ...CUSTOMER_BLANK_FORMS,
  productDescriptions: [],
}

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  headerTitleContainer: {
    flexGrow: "1",
    margin: "0",
  },
  headerTitle: {
    fontWeight: "bold",
  },
  buttonEdit: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  buttonIcon: {
    marginLeft: "-5px",
    marginRight: "5px",
  },
  fieldLabel: {
    margin: "10px 0",
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
  },
  commentNotesLabel: {
    fontWeight: "bold",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  tableTitle: {
    margin: "0",
    marginLeft: "-20px",
    fontSize: theme.typography.body1.fontSize,
  },
  sectionTitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  tableWrapper: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  commentDisplay: {
    whiteSpace: "pre-wrap",
    marginTop: "0",
    wordBreak: "break-word",
  },
  sectionTotalTable: {
    marginLeft: "auto",
    textAlign: "right",
    "& th": {
      paddingRight: "1rem",
    },
    "& tr": {
      marginBottom: "0.3rem",
    },
  },
  totalCostsTable: {
    textAlign: "right",
    "& tr > *": {
      padding: "0.4rem 0.6rem",
    },
    "& thead > tr > *": {
      verticalAlign: "top",
    },
    "& tr$totalRow > *": {
      borderTop: "1px solid black",
    },
  },
  totalRow: {},
  priceCellHead: {
    "& > *": {
      justifyContent: "flex-end",
    },
  },
  priceCellBody: {
    textAlign: "right",
  },
  purchaseGoIcon: {
    marginRight: "-0.2rem",
  },
}))
