/* eslint-disable react/prop-types */
import React, { useLayoutEffect, useMemo } from "react"
import PERMISSION_CODES from "constants/permission-codes"
import ApiSalesQuotation from "services/api-sales-quotation"
import ApiCustomer from "services/api-customer"
import {
  sendAndHandleInvalidToken,
  useWrapHandleInvalidToken as useWrap,
} from "utils/invalid-token"
import useShowSnackbar from "utils/snackbar-hooks"
import useCheckPermission from "utils/auth/useCheckPermission"
import { useHistory, useRouteMatch } from "react-router-dom"
// import ApiWorkOrder from "services/api-work-order"
import NotAllowedNotice from "components/NotAllowedNotice"
import WOForm from "components/work-order/WOForm"
import ApiWorkOrder from "services/api-work-order"
// import WOForm from "components/work-order/WOForm"

const PAGE_PERMISSIONS = [PERMISSION_CODES.WRITE_WORK_ORDER]

const fetchSalesQuotation = async () =>
  ((await ApiSalesQuotation.getAllExecutedItem()) || []).data
const fetchCustomerData = async () => ((await ApiCustomer.get()) || []).data
const fetchEstimateData = async (id) =>
  ((await ApiSalesQuotation.getEstimateItem(id)) || []).data
const fetchWoTypeData = async () =>
  ((await ApiWorkOrder.getWorkOrderType()) || []).data
const fetchMouldCode = async (val) =>
  (
    (await ApiWorkOrder.getAllMoldCode({
      limit: 10,
      fromColumns: ["mouldCode"],
      searchText: val,
    })) || []
  ).data
export default function WorkOrderUpdatePage({
  id,
  formData,
  setFormData = () => {},
}) {
  const wrappedFetchSalesQuotation = useWrap(fetchSalesQuotation, () => [])
  const wrappedFetchCustomerData = useWrap(fetchCustomerData, () => [])
  const wrappedFetchEstimateData = useWrap(fetchEstimateData, () => [])
  const wrappedFetchWoTypeData = useWrap(fetchWoTypeData, () => [])
  const wrappedFetchMouldCodeData = useWrap(fetchMouldCode, () => [])

  const [showSnackbar, showSnackbarLoading, hideSnackbar] = useShowSnackbar()
  const { allowed, checking, initialized, check } = useCheckPermission()
  useLayoutEffect(() => {
    check({ permissions: PAGE_PERMISSIONS })
  }, [check])

  const history = useHistory()

  const { checkWithoutUpdateState } = useCheckPermission()

  const canDeleteWorkOrderEstimate = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.DELETE_WORK_ORDER_ESTIMATE],
      }),
    [checkWithoutUpdateState]
  )

  const match = useRouteMatch()
  const editPagePath = match.path
  const itemPagePath = editPagePath.replace(/(.*)\/edit(?:\/)?$/, "$1")
  const updatePostedWorkOrder = useMemo(() => formData?.progress === "post", [
    formData,
  ])

  const handleSubmit = async (paramFormData) => {
    const newFormData = Object.keys(paramFormData).reduce((object, key) => {
      if (key !== "") {
        // eslint-disable-next-line no-param-reassign
        object[key] = paramFormData[key]
      }
      return object
    }, {})
    if (updatePostedWorkOrder) {
      delete newFormData.customerId
      // delete newFormData.status
    }
    showSnackbarLoading("Updating data...")

    const updateFunction = updatePostedWorkOrder
      ? ApiWorkOrder.updatePostedWorkOrder
      : ApiWorkOrder.updateWorkOrder
    let fetchState = {}

    try {
      fetchState = await sendAndHandleInvalidToken(() =>
        updateFunction(id, newFormData)
      )
    } catch (e) {
      const errorMessage = e?.message
      const message = errorMessage

      // const WO_UNIQUE_ERROR = 'violates unique constraint "wo_number_unique"'

      showSnackbar(message)
      return
    }

    if (fetchState.success) {
      showSnackbar("Data was updated successfully.")
      setFormData(fetchState.response.data)
      history.push(itemPagePath)
    } else {
      hideSnackbar()
    }
  }

  const handleCancel = () => {
    history.push(itemPagePath)
  }

  if (checking || !initialized) {
    return <></>
  }
  if (!allowed) {
    return <NotAllowedNotice />
  }

  return (
    <WOForm
      fetchCustomerData={wrappedFetchCustomerData}
      fetchQuotationData={wrappedFetchSalesQuotation}
      fetchEstimateData={wrappedFetchEstimateData}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      initialFormData={formData}
      fetchMouldCodeData={wrappedFetchMouldCodeData}
      isUpdate
      fetchWoTypeData={wrappedFetchWoTypeData}
      updatePostedWorkOrder={updatePostedWorkOrder}
      canDeleteWorkOrderEstimate={canDeleteWorkOrderEstimate}
    />
  )
}
