/* eslint-disable react/prop-types */
import { IconButton, Tooltip, Typography } from "@material-ui/core"
import { Edit, MoreVert, Print } from "@material-ui/icons"
import ChangeStatusMenu from "components/common/ChangeStatusMenu"
import React, { useRef, useState } from "react"

function InvoiceItemDisplayHeader({
  classes,
  showEdit,
  data,
  title,
  onConfirmEdit,
  modalOpen,
  setModalOpen,
  onConfirmChangeStatus,
}) {
  const anchorElRef = useRef(null)
  const [openStatusChangeMenu, setOpenStatusChangeMenu] = useState(false)

  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerTitleContainer}>
        <Typography variant="h6" className={classes.headerTitle}>
          {title}
        </Typography>
      </div>

      {showEdit && data.paymentStatus === "unpaid" && (
        <Tooltip title="Edit">
          <IconButton
            variant="contained"
            color="primary"
            onClick={onConfirmEdit}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Print">
        <IconButton
          id="print-icon"
          aria-expanded={modalOpen ? "true" : undefined}
          aria-controls={modalOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={() => setModalOpen(true)}
        >
          <Print fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Set Status">
        <IconButton
          color="primary"
          ref={anchorElRef}
          onClick={() => setOpenStatusChangeMenu(true)}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <ChangeStatusMenu
        open={openStatusChangeMenu}
        itemType="Invoice"
        anchorEl={anchorElRef.current}
        showCancel
        onClose={() => setOpenStatusChangeMenu(false)}
        onConfirmStatusChange={onConfirmChangeStatus}
      />
    </div>
  )
}

export default InvoiceItemDisplayHeader
