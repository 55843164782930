/* eslint-disable react/forbid-prop-types */

import { TableCell, TableRow, TextField } from "@material-ui/core"
import React, { useContext, useMemo } from "react"
import * as t from "prop-types"
import InvoiceFormContext from "./InvoiceFormContex"
import { currencyOperation } from "./utils"

function ProductDescriptionRow({ product, index, register }) {
  const {
    selectedCurrency,
    paymentPercentage,
    currencyRate,
    rateIn,
  } = useContext(InvoiceFormContext)

  const totalCostWithCurrency = useMemo(() => {
    return currencyOperation(product.totalCost, rateIn, currencyRate)
  }, [currencyRate, product.totalCost, rateIn])

  const totalCostWithPercentage = useMemo(() => {
    if (!paymentPercentage) return totalCostWithCurrency
    return totalCostWithCurrency * (0 + paymentPercentage / 100)
  }, [paymentPercentage, totalCostWithCurrency])
  return (
    <TableRow>
      <TableCell>
        <p>{product.dnNumber}</p>
      </TableCell>
      <TableCell>
        <p>{product.poNumber}</p>
      </TableCell>
      <TableCell>
        <p style={{ whiteSpace: "pre-line" }}>{product.description}</p>
        <input
          type="hidden"
          name={`productDescriptions[${index}].description`}
          value={product.description}
          ref={register}
        />
        <input
          type="hidden"
          name={`productDescriptions[${index}].dnDetailId`}
          ref={register}
          value={product.idDnDetail}
        />
      </TableCell>
      <TableCell>
        <p>{product.quantity}</p>
        <input
          type="hidden"
          name={`productDescriptions[${index}].quantity`}
          value={product.quantity}
          ref={register()}
        />
      </TableCell>
      <TableCell>
        <p>{product.unit}</p>
        <input
          type="hidden"
          name={`productDescriptions[${index}].unit`}
          value={product.unit}
          ref={register()}
        />
      </TableCell>
      <TableCell>
        {selectedCurrency?.code}{" "}
        {new Intl.NumberFormat().format(
          Math.ceil(totalCostWithCurrency / +product.quantity)
        )}
        <input
          type="hidden"
          name={`productDescriptions[${index}].unitPrice`}
          ref={register()}
          value={product.totalCost}
        />
      </TableCell>
      <TableCell>
        {selectedCurrency?.code}{" "}
        {new Intl.NumberFormat().format(Math.ceil(totalCostWithPercentage))}
      </TableCell>
      <TableCell>
        <TextField
          name={`productDescriptions[${index}].sequence`}
          defaultValue={product.sequence || index + 1}
          inputRef={register}
          margin="dense"
          fullWidth
          variant="outlined"
          type="number"
        />
      </TableCell>
    </TableRow>
  )
}

ProductDescriptionRow.propTypes = {
  product: t.shape({
    description: t.string,
    unit: t.string,
    quantity: t.number,
    totalCost: t.number,
    idDnDetail: t.number,
    dnNumber: t.string,
    poNumber: t.string,
    sequence: t.number,
  }),
  index: t.number,
  register: t.any.isRequired,
}

ProductDescriptionRow.defaultProps = {
  product: {
    description: "",
    unit: "",
    quantity: 0,
    totalCost: 0,
    idDnDetail: "",
    dnNumber: "",
    poNumber: "",
    sequence: 0,
  },
  index: 0,
}
export default ProductDescriptionRow
