import React from "react"
import {
  additionalEstimatesToPrice,
  findSalesEstimatesActiveReduction,
  materialEstimatesToPrice,
  processEstimatesToPrice,
} from "components/sales-quotation/utils"
import { toDict } from "utils/objects"

export function getDefaultSettings(
  name,
  label = "",
  // eslint-disable-next-line no-unused-vars
  register = null,
  errors = null
) {
  const errorObj = (errors || {})[name]

  return {
    name,
    label,
    fullWidth: true,
    margin: "dense",
    error: !!errorObj,
    helperText: errorObj?.message ?? "",
    inputRef: register,
  }
}

export function simplifyDNProductDetails(items) {
  return items.map((item) => {
    const estQuot = item?.salesEstimate?.estQuot ?? {}
    const processCost = processEstimatesToPrice(
      estQuot?.processCosts || [],
      toDict(
        (estQuot?.processCost || []).map((process) => {
          return { ...process.process, processId: process.processId }
        }),
        "processId"
      )
    ).reduce((a, b) => a + b, 0)

    const materialCost = materialEstimatesToPrice(
      estQuot?.materialCosts ?? [],
      toDict(
        (estQuot?.materialCosts || []).map((m) => ({
          ...m.material,
          dimension: m.dimension,
          materialId: m.materialId,
          density: m.material.materialType.density,
        })),
        "materialId"
      )
    ).reduce((a, b) => a + b, 0)

    const additionalCost = additionalEstimatesToPrice(
      estQuot?.additionalCosts ?? []
    ).reduce((a, b) => a + b, 0)
    const outsourcingCost = additionalEstimatesToPrice(
      estQuot?.outsourcingCosts || []
    ).reduce((a, b) => a + b, 0)

    const processHandling = parseFloat(estQuot?.processCostHandling) || 0
    const materialHandling = parseFloat(estQuot?.materialCostHandling) || 0
    const additionalHandling = parseFloat(estQuot?.additionalCostHandling) || 0
    const outsourcingHandling =
      parseFloat(estQuot?.outsourcingCostHandling) || 0

    const activeReduction = findSalesEstimatesActiveReduction(
      item?.salesEstimate
    )

    const unitPrice = !item.unitPrice
      ? processCost * (1 + processHandling / 100) +
        materialCost * (1 + materialHandling / 100) +
        additionalCost * (1 + additionalHandling / 100) +
        outsourcingCost * (1 + outsourcingHandling / 100)
      : item.unitPrice

    const totalCost = !item.unitPrice
      ? parseFloat(item?.salesEstimate?.quantity ?? 1) *
          unitPrice *
          (1 + (item?.handling / 100 || 0)) -
        (activeReduction?.reduction ?? 0)
      : item.unitPrice

    const discount = item.discount || item.salesEstimate?.discount || 0

    return {
      totalCost: totalCost - discount,
      unitPrice: unitPrice - discount,
      description: item.description,
      quantity: item.quantity,
      unit: item.unit,
      idDnDetail: item.idDnDetail,
      poNumber: item.salesEstimate?.slsPurchaseOrder?.poNumber,
      reduction: activeReduction,
    }
  })
}

export function currencyOperation(value, rateIn, currencyRate) {
  if (rateIn?.code === "IDR") {
    return value / (currencyRate ?? 1)
  }
  return value * (currencyRate ?? 1)
}

export function generateProductDescriptions({
  data,
  arr,
  isDebitNote,
  isMergeDetail,
}) {
  return arr.map((product, i) => {
    let description = (
      <strong style={{ whiteSpace: "pre-line" }}>{product.description}</strong>
    )

    if (isMergeDetail) {
      description = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "80%", whiteSpace: "pre-line" }}>
            <span style={{ display: "block" }}>{product.description}</span>
            <span>{product.remark}</span>
          </div>
          <strong style={{ textAlign: "right" }}>{product?.mouldCode}</strong>
        </div>
      )
    }

    if (!isDebitNote) {
      description = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "80%", whiteSpace: "pre-line" }}>
            <span style={{ display: "block" }}>{product.description}</span>
            <span>{product.remark}</span>
          </div>
        </div>
      )
    }

    const quantity = `${product.quantity} ${product.unit}`

    const price =
      isDebitNote || isMergeDetail
        ? product.unitPrice
        : product.unitPrice / (product.quantity || 1)

    const unitPrice = Math.ceil(
      currencyOperation(price, data.rateIn, data.currencyRate)
    )

    const total = Math.ceil(
      data.paymentPercentage
        ? unitPrice *
            (product.quantity || 1) *
            (0 + data.paymentPercentage / 100)
        : unitPrice * (product.quantity || 1)
    )
    const totalForCalculation = total

    return {
      no: i + 1,
      description,
      quantity,
      unitPrice: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <span>{data.currency.code}</span>
          <span>{new Intl.NumberFormat().format(unitPrice)}</span>
        </div>
      ),
      total: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <span>{data.currency.code}</span>
          <span>{new Intl.NumberFormat().format(total)}</span>
        </div>
      ),
      totalForCalculation,
      projectName: product.projectName ?? "",
      orderNumber: !isDebitNote ? (
        <strong>Order No: {product?.mouldCode}</strong>
      ) : null,
    }
  })
}
