/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react"
import * as t from "prop-types"
import { Button, Grid } from "@material-ui/core"
import { formatDate } from "utils/form-data"
import ItemDisplay from "components/common/ItemDisplay"
import MUIDataTable from "mui-datatables"
import InvoiceItemPrint from "components/invoice/InvoiceItemPrint"
import { useReactToPrint } from "react-to-print"
import { uniqBy } from "lodash"
// import useShowSnackbar from "utils/snackbar-hooks"
// import ApiInvoice from "services/api-invoice"
// import ChangeStatusMenu from "components/common/ChangeStatusMenu"
// import { sendAndHandleInvalidToken } from "utils/invalid-token"
import { currencyOperation, generateProductDescriptions } from "./utils"
import InvoiceMergeForm from "./InvoiceMergeForm"
import { useStyles } from "./const"
import TotalCostTable from "./TotalCostTable"
import InvoiceItemDisplayHeader from "./InvoiceItemDisplayHeader"
import InvoicePrintSettingDialog from "./InvoicePrintSettingDialog"
import PackingListPrint from "./PackingListPrint"

function InvoiceItemDisplay({
  showEdit,
  onConfirmEdit,
  data,
  companyInformation,
  onConfirmChangeStatus,
  bankAccount,
  id,
  setFlagRefetch,
  canUpdatePaymentStatus,
  onUpdatePaymentStatus,
}) {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)

  const [selections, setSelections] = useState([])

  const isDebitNote = useMemo(() => data.invoiceType === "DEBIT NOTE", [
    data.invoiceType,
  ])

  const productDescriptionList = useMemo(() => {
    if (!data.productDescriptions) return []
    return data.productDescriptions.map((product) => {
      const { slsEstimate, productDetail } = product
      const unitPrice = isDebitNote
        ? product.unitPrice
        : product.unitPrice / product.quantity
      return {
        ...product,
        unitPrice: Math.ceil(unitPrice),
        slsEstimate: slsEstimate ?? productDetail?.salesEstimate ?? null,
      }
    })
  }, [data.productDescriptions, isDebitNote])

  const [printSetting, setPrintSetting] = useState({})

  const salesPurchaseOrder = useMemo(() => {
    const arr = []

    productDescriptionList.forEach((pd) => {
      const { details = [], slsPurchaseOrder } = pd.slsEstimate ?? {}
      if (!details.length || (!printSetting.showBreakdown && slsPurchaseOrder))
        arr.push({
          ...slsPurchaseOrder,
          slsQuotNumber: pd?.slsEstimate?.slsQuot?.slsQuotNumber,
        })

      if (printSetting.showBreakdown) {
        details.forEach((d) => {
          if (d.slsPurchaseOrder)
            arr.push({
              ...d.slsPurchaseOrder,
              slsQuotNumber: pd?.slsEstimate?.slsQuot?.slsQuotNumber,
            })
        })
      }
    })

    return arr
  }, [printSetting, productDescriptionList])

  const descriptions = React.useMemo(() => {
    return data.productDescriptions || []
  }, [data])

  const generalFields = [
    { attr: "invoiceNumber", label: "Invoice Number" },

    {
      attr: "date",
      label: "Invoice Date",
      display: (date) => formatDate(date),
    },
    {
      attr: "payment",
      label: "Payment",
      display: (payment) => payment ?? "-",
    },
    {
      attr: "paymentPercentage",
      label: "%",
      display: (paymentPercentage) => paymentPercentage ?? "-",
    },
    {
      attr: "authorizedSignature",
      label: "Signed By",
      display: (authorizedSignature) => authorizedSignature?.name ?? "-",
    },
    { attr: "remarks", label: "Remarks" },
    {
      attr: "notes",
      label: "Notes",
      display: (val) => <span style={{ whiteSpace: "pre-line" }}>{val}</span>,
    },
    { attr: "paymentTerms", label: "Payment Terms" },
    {
      attr: "paidDate",
      label: "Paid Date",
      display: (val) => (val ? formatDate(val) : "-"),
    },
  ]

  const customerFields = [
    {
      attr: "customer",
      label: "Customer",
      display: (customer) => customer?.name ?? "-",
    },
    { attr: "address", label: "Address" },
    { attr: "attn", label: "Attn" },
    {
      attr: "currency",
      label: "Invoice Currency",
      display: (currency) => `${currency?.description} (${currency?.code})`,
    },
    {
      attr: "currencyRate",
      label: "Currency Rate",
    },
  ]

  const itemDisplayProps = {
    data,
    showEditButton: false,
    showDeleteButton: false,
  }

  const title = `Invoice of “${data.invoiceNumber}”`

  const tableNumberColumn = {
    name: "no",
    label: "No",
    options: {
      setCellHeaderProps: () => ({
        style: {
          width: "1px",
        },
      }),
      customBodyRenderLite: (index) => index + 1,
    },
  }

  const tableOptions = {
    elevation: 0,
    download: false,
    print: false,
    selectToolbarPlacement: "none",
    responsive: "standard",
    selectableRows: "multiple",
    isRowSelectable: (dataIndex) => {
      const item = data.productDescriptions[dataIndex]
      return !item?.invoiceDetailMergeId
    },
    onTableChange: (...args) => {
      const [action, tableState] = args
      if (action === "rowSelectionChange") {
        const indexes = tableState.selectedRows.data.map(
          (item) => item.dataIndex
        )
        setTimeout(() => {
          setSelections(indexes.map((idx) => descriptions[idx].idInvoiceDetail))
        }, 1000)
      }
    },
  }

  const standardColumn = [tableNumberColumn]

  const pricingColumn = useMemo(() => {
    return [
      { name: "quantity", label: "Quantity" },
      { name: "unit", label: "Unit" },
      {
        name: "unitPrice",
        label: "Unit Price",
        options: {
          customBodyRender: (unitPrice) => {
            if (isDebitNote) return new Intl.NumberFormat().format(unitPrice)
            return `${new Intl.NumberFormat().format(
              Math.ceil(
                currencyOperation(unitPrice, data.rateIn, data.currencyRate)
              )
            )}`
          },
        },
      },
      {
        name: "totalCost",
        label: "Total Cost",
        options: {
          customBodyRenderLite: (index) => {
            const item = data.productDescriptions[index]

            let unitPrice = Math.ceil(
              currencyOperation(item.unitPrice, data.rateIn, data.currencyRate)
            )

            if (isDebitNote) unitPrice *= item.quantity || 1

            if (!data.paymentPercentage)
              return `${new Intl.NumberFormat().format(unitPrice)}`
            return `${new Intl.NumberFormat().format(
              unitPrice * (0 + data.paymentPercentage / 100)
            )}`
          },
        },
      },
    ]
  }, [
    data.currencyRate,
    data.paymentPercentage,
    data.productDescriptions,
    data.rateIn,
    isDebitNote,
  ])

  const columnDebitNoteOptions = useMemo(() => {
    return [
      ...standardColumn,
      {
        name: "description",
        label: "Description",
        options: {
          customBodyRender: (description) => {
            return <span style={{ whiteSpace: "pre-line" }}>{description}</span>
          },
        },
      },
      ...pricingColumn,
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currencyRate, data.paymentPercentage, data.productDescriptions])

  const dnNumberUnique = useMemo(() => {
    return Array.from(
      new Set(
        data.productDescriptions.map(
          (item) => item.productDetail?.deliveryNote?.dnNumber
        )
      )
    )
  }, [data.productDescriptions])

  const poNumberUnique = useMemo(() => {
    return uniqBy(salesPurchaseOrder, "poNumber")
  }, [salesPurchaseOrder])

  const poDateUnique = useMemo(() => {
    return uniqBy(salesPurchaseOrder, "poDate")
  }, [salesPurchaseOrder])

  const columnInvoiceOptions = useMemo(
    () => {
      const col = [
        ...standardColumn,
        {
          name: "dnNumber",
          label: "DN Number",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.productDescriptions[index]
              return item?.productDetail?.deliveryNote?.dnNumber ?? "-"
            },
          },
        },
        {
          name: "poNumber",
          label: "PO Number",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.productDescriptions[index]
              return (
                item?.productDetail?.salesEstimate?.slsPurchaseOrder
                  ?.poNumber ??
                item?.slsEstimate?.slsPurchaseOrder?.poNumber ??
                "-"
              )
            },
          },
        },
        {
          name: "refQuot",
          label: "Ref Quot",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.productDescriptions[index]
              return (
                item?.productDetail?.salesEstimate?.slsQuot?.slsQuotNumber ??
                item?.slsEstimate?.slsQuot?.slsQuotNumber ??
                "-"
              )
            },
          },
        },
        {
          name: "description",
          label: "Description",
          options: {
            customBodyRender: (val) => (
              <span style={{ whiteSpace: "pre-line" }}>{val}</span>
            ),
          },
        },

        {
          name: "productDetail",
          label: "Mould Code",
          options: {
            customBodyRenderLite: (index) => {
              const item = data.productDescriptions[index]
              const { relatedWorkOrderEstimate } = item?.slsEstimate || {}
              const mouldCode =
                (relatedWorkOrderEstimate?.edges[0] || {}).node?.mouldCode ?? ""
              return item?.productDetail?.mouldCode ?? mouldCode
            },
          },
        },
        ...pricingColumn,
      ]

      if (data.invoiceType === "INVOICE EXPORT") {
        col.push({
          name: "remark",
          label: "Remark",
          options: {
            customBodyRender: (val) => (
              <span style={{ whiteSpace: "pre-line" }}>{val}</span>
            ),
          },
        })
      }

      return col
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  const componentRef = React.useRef()
  const packingListRef = React.useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const handlePrintPackingList = useReactToPrint({
    content: () => {
      const invoicePage = componentRef.current.cloneNode(true)
      const packingListPage = packingListRef.current.cloneNode(true)

      const container = document.createElement("div")
      container.style.pageBreakAfter = "always"
      container.appendChild(invoicePage)

      const printElem = document.createElement("div")
      printElem.appendChild(container)
      printElem.appendChild(packingListPage)

      return printElem
    },
  })

  const projectNameRows = useMemo(() => {
    const arr = []
    data.productDescriptions.forEach((pd) => {
      const relatedWorkOrder =
        pd.productDetail?.salesEstimate?.relatedWorkOrderEstimate?.edges ||
        pd.slsEstimate?.relatedWorkOrderEstimate?.edges ||
        []
      const currentProject = Array.from(
        new Set(
          (relatedWorkOrder || []).map(
            // (edge) => edge?.node?.workOrder?.projectName
            (edge) => edge?.node?.description
          )
        )
      ).join(", ")
      arr.push(!arr.includes(currentProject) ? currentProject : "")
    })
    return arr
  }, [data.productDescriptions])

  const productDescriptions = useMemo(() => {
    if ((data?.mergedDetails || []).length) {
      return generateProductDescriptions({
        data,
        arr: data.mergedDetails.map((product) => {
          return {
            ...product,
            unitPrice: Math.ceil(
              product.details.reduce((a, b) => {
                return (
                  a +
                  currencyOperation(b.unitPrice, data.rateIn, data.currencyRate)
                )
              }, 0)
            ),
          }
        }),
      })
    }
    if (printSetting.showBreakdown) {
      const arr = []
      productDescriptionList.forEach((pd, index) => {
        const { relatedWorkOrderEstimate, details = [] } = pd?.slsEstimate || {}
        const mouldCode =
          (relatedWorkOrderEstimate?.edges[0] || {}).node?.mouldCode ?? "-"

        if (details.length) {
          details.forEach((d, i) => {
            arr.push({
              ...d,
              mouldCode: pd?.productDetail?.mouldCode ?? mouldCode,
              projectName: i === 0 ? projectNameRows[index] : null,
              unit: d.uom?.name,
            })
          })
        } else {
          arr.push({
            ...pd,
            mouldCode: pd?.productDetail?.mouldCode ?? mouldCode,
            projectName: projectNameRows[index],
          })
        }
      })
      return generateProductDescriptions({ arr, data, isDebitNote })
    }
    return generateProductDescriptions({
      data,
      arr: data.productDescriptions.map((product, index) => {
        const { relatedWorkOrderEstimate } = product?.slsEstimate || {}
        const mouldCode =
          (relatedWorkOrderEstimate?.edges[0] || {}).node?.mouldCode ?? "-"

        return {
          ...product,
          mouldCode: product?.productDetail?.mouldCode ?? mouldCode,
          projectName: projectNameRows[index],
        }
      }),
    })
  }, [
    data,
    isDebitNote,
    printSetting.showBreakdown,
    productDescriptionList,
    projectNameRows,
  ])

  // const anchorElRef = useRef(null)
  // const [openStatusChangeMenu, setOpenStatusChangeMenu] = useState(false)

  // const handleMergeInvoice = async (val) => {
  //   let fetchState = {}
  //   try {
  //     showSnackbarLoading("Loading...")

  //     if (selections.length) {
  //       fetchState = await sendAndHandleInvalidToken(() =>
  //         ApiInvoice.mergeInvoiceDetail({
  //           ...val,
  //           invoiceDetailId: selections.map((s) => parseInt(s, 10)),
  //           invoiceId: parseInt(id, 10),
  //         })
  //       )
  //     } else {
  //       fetchState = await sendAndHandleInvalidToken(() =>
  //         ApiInvoice.updateMergeInvoiceDetail({
  //           ...val,
  //           invoiceDetailId: selections.map((s) => parseInt(s, 10)),
  //           invoiceId: parseInt(id, 10),
  //         })
  //       )
  //     }
  //     if (fetchState.success) {
  //       showSnackbar("Invoice detail merged successfully!")
  //       setFlagRefetch()
  //     } else {
  //       hideSnackbar()
  //     }
  //   } catch (error) {
  //     showSnackbar(error?.errors[0]?.message || "Something went wrong")
  //   }
  // }
  // const handleDeleteMergeInvoice = async (invoiceDetailMergeId) => {
  //   let fetchState = {}
  //   try {
  //     showSnackbarLoading("Loading...")

  //     fetchState = await sendAndHandleInvalidToken(() =>
  //       ApiInvoice.deleteMergeInvoiceDetail(parseInt(invoiceDetailMergeId, 10))
  //     )
  //     if (fetchState.success) {
  //       showSnackbar("Delete susccess")
  //       setFlagRefetch()
  //     } else {
  //       hideSnackbar()
  //     }
  //   } catch (error) {
  //     showSnackbar("Something went wrong")
  //   }
  // }
  const handlePrintSettingClose = (val) => {
    setPrintSetting(val)
    setModalOpen(false)
    setTimeout(() => {
      if (val.withPackingList) {
        handlePrintPackingList()
      } else {
        handlePrint()
      }
    }, 1000)
  }

  return (
    <>
      <InvoiceItemPrint
        companyInformation={companyInformation}
        ref={componentRef}
        data={data}
        productDescription={productDescriptions}
        dnNumberUnique={dnNumberUnique}
        poDateUnique={poDateUnique}
        poNumberUnique={poNumberUnique}
        bankAccount={bankAccount}
        {...printSetting}
      />
      <PackingListPrint
        companyInformation={companyInformation}
        ref={packingListRef}
        data={data}
        productDescription={productDescriptions.map((pd, i) => {
          return {
            ...pd,
            grossWeight: (data?.productDescriptions ?? [])[i]?.grossWeight,
            netWeight: (data?.productDescriptions ?? [])[i]?.netWeight,
            totalPackingList: (data?.productDescriptions ?? [])[i]
              ?.totalPackingList,
          }
        })}
        dnNumberUnique={dnNumberUnique}
        poDateUnique={poDateUnique}
        poNumberUnique={poNumberUnique}
        {...printSetting}
      />
      <InvoicePrintSettingDialog
        open={modalOpen}
        onClose={handlePrintSettingClose}
      />
      <InvoiceItemDisplayHeader
        classes={classes}
        showEdit={showEdit}
        data={data}
        title={title}
        onConfirmEdit={onConfirmEdit}
        onConfirmChangeStatus={onConfirmChangeStatus}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      {canUpdatePaymentStatus && data?.paymentStatus !== "paid" ? (
        <Button
          color="primary"
          variant="contained"
          onClick={onUpdatePaymentStatus}
        >
          Mark as Paid
        </Button>
      ) : null}
      <Grid container>
        <Grid item xs={12} sm={6}>
          <ItemDisplay
            {...itemDisplayProps}
            attrsAndLabels={generalFields}
            title="General Data"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ItemDisplay
            {...itemDisplayProps}
            attrsAndLabels={customerFields}
            title="Customer Data"
          />
        </Grid>
      </Grid>

      <div className={classes.tableWrapper}>
        <MUIDataTable
          title={
            <div className={classes.tableTitle}>
              <strong>Description</strong>
            </div>
          }
          data={productDescriptionList}
          columns={isDebitNote ? columnInvoiceOptions : columnDebitNoteOptions}
          options={tableOptions}
        />
      </div>

      <TotalCostTable data={data} classes={classes} />
      {showEdit && (
        <InvoiceMergeForm
          disabled={!selections.length}
          defaultValue={data.mergedDetails}
          selections={selections}
          id={id}
          setFlagRefetch={setFlagRefetch}
        />
      )}
    </>
  )
}

InvoiceItemDisplay.propTypes = {
  showEdit: t.bool.isRequired,
  onConfirmEdit: t.func.isRequired,
  data: t.shape({
    invoiceNumber: t.string.isRequired,
    invoiceType: t.string.isRequired,
    paymentPercentage: t.number,
    date: t.string.isRequired,
    customer: t.shape({
      name: t.string.isRequired,
    }),
    deliveryNote: t.shape({
      dnNumber: t.string,
      poNumber: t.string,
      poDate: t.string,
      salesQuotationNumber: t.shape({
        slsQuotNumber: t.string,
      }),
    }),
    currency: t.shape({
      code: t.string,
      description: t.string,
    }),
    currencyRate: t.number,
    productDescriptions: t.arrayOf(
      t.shape({
        description: t.string,
        unitPrice: t.number,
        productDetail: t.shape({
          mouldCode: t.string,
        }),
      })
    ),
    discount: t.number,
    vat: t.number,
  }).isRequired,
  companyInformation: t.shape({
    companyName: t.string,
    phone: t.string,
    email: t.string,
    address: t.string,
  }).isRequired,
}

export default InvoiceItemDisplay
