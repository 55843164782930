/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core"
import t from "prop-types"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"

import CancelIcon from "@material-ui/icons/Cancel"
import ResetIcon from "@material-ui/icons/Refresh"
import { sendAndHandleInvalidToken } from "utils/invalid-token"
import ApiUploadFile from "services/api-upload-file"
import {
  getApiFileUrl,
  normalizeApiImageUrl,
  getFilenameFromApiUrl,
  trimFilename,
} from "utils/text-utils"
import clsx from "clsx"
import ImageDisplay from "../ImageDisplay"

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".scm-file-field__image": {
      width: "100%",
      height: "auto",
      background: "white",
      border: "thick solid #033278",
      boxShadow: "0px 0px 5px 1px #0000005e",
      overflow: "hidden",
      borderRadius: 12,
    },
    ".scm-file-field__content": {
      display: "flex",
      alignItems: "center",
      margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },
    ".scm-file-field__content > *": {
      margin: `0 ${theme.spacing(0.5)}px`,
    },
    ".scm-file-field__info": { display: "flex", alignItems: "center" },
    ".scm-file-field__no-file": {
      fontStyle: "italic",
      color: "#213e69ad",
    },
    ".scm-file-field__file-name": {
      wordBreak: "break-word",
    },
    ".scm-file-field__upload-progress": {
      flexGrow: 1,
      marginLeft: `${theme.spacing(1)}px`,
    },
    ".scm-file-field__upload-wrapper": {
      display: "flex",
      margin: `${theme.spacing(1)}px 0`,
    },
    ".scm-file-field__drop-notice": {
      opacity: 0,
      transition: "0.3s opacity ease, 0.3s background ease",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#15354fc9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      borderRadius: 8,
      "&.scm-file-field__drop-notice--show": {
        opacity: 1,
      },
      "&.scm-file-field__drop-notice--dragging-inside": {
        background: "#0c283ff2",
        fontWeight: "bold",
        "& span": {
          transform: "scale(1.3)",
        },
      },
      "& span": {
        transition: "0.1s transform ease",
        fontSize: "1.1rem",
        display: "block",
      },
    },
    ".scm-file-field--droppable": {
      padding: "1rem",
      borderRadius: "9px",
      background: "#03327817",
      border: "thin solid #0a36784d",
      "& .scm-file-field__content": {
        flexFlow: "column",
      },
      "& .scm-file-field__action": {
        flexFlow: "column",
        display: "flex",
      },
    },
    ".scm-file-field__root--focusable": {
      border: "thin solid transparent",
      borderRadius: 4,
      padding: theme.spacing(1),
      "&:focus": {
        borderColor: "black",
      },
    },
  },
}))

function LinearProgressWithLabel(props) {
  const { value, className } = props
  return (
    <Box className={className} display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {value !== null && (
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            value
          )}%`}</Typography>
        )}
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: t.number,
  className: t.string,
}

LinearProgressWithLabel.defaultProps = {
  value: null,
  className: null,
}

const prettifyFileSize = (byteSize) => {
  const base = Math.floor(Math.log2(byteSize) / 10)
  const metrics = ["Tb", "Gb", "Mb", "Kb", "bytes"]
  const indexBase = Math.max(metrics.length - base - 1, 0)
  const indexMultiply = Math.min(base, metrics.length - 1)
  const basePretty =
    Math.round((byteSize / 2 ** (indexMultiply * 10)) * 100) / 100
  return [basePretty, metrics[indexBase]]
}

/**
 * Example usage: see `EQLWTRateForm.js`
 *
 * Props:
 * - `value` = Filename from upload filebox or relative path to file.
 *
 * - `changed` = Status whether file is changed or not.
 *    If `true` and `value` exists, the URL used for image display will be
 *    set to `<envApi>/<value>` for image display src.
 *    You should set `changed` to be `true`
 *    on every `onChange` event coming from this component.
 *
 * - `onChange` = Callback fired when file selected by user is fired.
 *
 * - `name` = Name attribute of the `input` element.
 *
 * - `error` = If `true`, the input will indicate an error.
 *
 * - `helperText` = The helper text content.
 *
 * - `image` = If `true`, the input will display the image if value exists.
 *
 * - `normalizeImagePath` = if `true`, the URL used for image display filename
 *    will be filename taken from <value> and prepended with `<base api>/apiimage/`.
 *    Example: `/some_path/some_deep_path/some_name.png`
 *    becomes `localhost/apiimage/some_name.png`
 *
 * - `disableDrop` = If `true`, select file by dropping will be disabled.
 *
 * - `fullWidth` = If `true`, the input will take up the full width of its container.
 *
 * - `imageDisplayProp` = Props passed to ImageDisplay
 */

const FileField = forwardRef(
  (
    {
      id,
      value,
      changed,
      onChange,
      onReset,
      name,
      error: propError,
      helperText: propHelperText,
      image,
      normalizeImagePath,
      disableDrop,
      fullWidth,
      imageDisplayProp,
      accept,
      style,
    },
    ref
  ) => {
    useStyles()

    const [windowDragging, setWindowDragging] = useState(false)
    const [isInitDraggingFile, setIsDraggingFile] = useState(false)
    const [draggingInside, setDraggingInside] = useState(false)

    const [renderDragNotice, setRenderDragNotice] = useState(false)
    const [showDragNotice, setShowDragNotice] = useState(false)
    const [transitioningOut, setTransitioningOut] = useState(false)

    const isDraggingFile = isInitDraggingFile && windowDragging
    const isDraggingButNotShowing = isDraggingFile && !showDragNotice

    useEffect(() => {
      if (isDraggingFile) {
        setRenderDragNotice(true)
      }
    }, [isDraggingFile])

    useEffect(() => {
      if (isDraggingButNotShowing && transitioningOut) {
        setShowDragNotice(true)
      }
    }, [isDraggingButNotShowing, transitioningOut])

    useEffect(() => {
      if (showDragNotice || !renderDragNotice) {
        setTransitioningOut(false)
      }
    }, [showDragNotice, renderDragNotice])

    // show drag notice after the element notice is rendered
    useEffect(() => {
      if (renderDragNotice) {
        setShowDragNotice(true)
      }
    }, [renderDragNotice])

    useEffect(() => {
      let timeout = null
      if (!isDraggingFile) {
        setShowDragNotice(false)
        setTransitioningOut(true)
        timeout = setTimeout(() => {
          setRenderDragNotice(false)
          setTransitioningOut(false)
        }, 300)
      }
      return () => {
        clearTimeout(timeout)
      }
    }, [isDraggingFile])

    const [uploading, setUploading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [progressing, setProgressing] = useState(false)

    const [chosenFile, setChosenFile] = useState(null)
    const [uploadedFile, setUploadedFile] = useState(null)
    const [localFileUrl, setLocalFileUrl] = useState("")

    useEffect(() => {
      if (!uploadedFile) {
        setLocalFileUrl((url) => {
          if (url) {
            window.URL.revokeObjectURL(url)
          }
          return ""
        })
      } else {
        setLocalFileUrl((url) => {
          if (url) {
            URL.revokeObjectURL(url)
          }
          return URL.createObjectURL(uploadedFile)
        })
      }
    }, [uploadedFile])

    const [uploadError, setUploadError] = useState("")
    const error = propError || !!uploadError
    const helperText = uploadError || propHelperText

    const fileFieldRef = useRef(null)
    const fileRef = useRef(null)
    const valueExists = !!value
    useEffect(() => {
      if (!changed) {
        setUploadedFile(null)
      }
    }, [changed])

    let fileUrl = ""
    if (localFileUrl) {
      fileUrl = localFileUrl
    } else if (!changed && !!value) {
      if (image && normalizeImagePath) {
        fileUrl = normalizeApiImageUrl(value)
      } else {
        fileUrl = getApiFileUrl(value)
      }
    }

    let filename = ""
    if (uploadedFile) {
      filename = uploadedFile.name
    } else if (valueExists) {
      filename = getFilenameFromApiUrl(value, { ignoreUnderscore: true })
    }
    filename = trimFilename(filename)

    const fileSizeFormat = useMemo(
      () => (uploadedFile ? prettifyFileSize(uploadedFile.size) : ""),
      [uploadedFile]
    )

    const onChangeRef = useRef(onChange)
    useEffect(() => {
      onChangeRef.current = onChange
    }, [onChange])

    useEffect(() => {
      if (!chosenFile) return
      const el = fileRef.current

      const upload = async () => {
        setProgress(0)
        setProgressing(false)
        setUploading(true)
        setUploadError("")

        let fetchState = {}
        try {
          const uploader = image
            ? ApiUploadFile.create
            : ApiUploadFile.uploadFile
          fetchState = await sendAndHandleInvalidToken(() =>
            uploader(chosenFile, (ev) => {
              setProgressing(true)
              setProgress(Math.round((100 * ev.loaded) / ev.total))
            })
          )
        } catch (err) {
          console.error(err)
          el.value = ""
          setUploading(false)
          setUploadError(err.message)
          return
        }

        if (fetchState.success) {
          setUploading(false)
          onChangeRef.current(fetchState.response.data.fileBox)
          setUploadedFile(chosenFile)
        }
        setProgress(0)
      }
      upload()
    }, [chosenFile, image])

    const handleFileChange = async (e) => {
      const el = e.target
      const eventFile = el.files[0]
      setChosenFile(eventFile)
      el.value = null
    }

    const handleClear = () => {
      setUploadedFile(null)
      onChange("")
    }

    const handleClickChoose = () => {
      fileRef.current.click()
    }

    const chooseFileBtnRef = useRef()

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          if (fileFieldRef.current) {
            fileFieldRef.current.scrollIntoView()
            fileFieldRef.current.focus()
          }
        },
        focusButtonChoose: () => {
          if (chooseFileBtnRef.current) {
            chooseFileBtnRef.current.focusVisible()
          }
        },
      }),
      []
    )

    useEffect(() => {
      if (disableDrop) {
        return () => {}
      }
      const handleDragEnter = (e) => {
        const allowedTypes = ["application/x-moz-file", "Files"]
        const allowedIndex = [0, 1]
        const dt = e.dataTransfer
        const types = dt.types || []
        const containsFiles = allowedIndex.some((index) =>
          allowedTypes.includes(types[index])
        )
        setIsDraggingFile(containsFiles)
        setWindowDragging(true)
      }
      const handleDragStart = (e) => {
        const dt = e.dataTransfer
        const types = dt.types || []
        const containsFiles =
          types[0] === "Files" ||
          (types.contains && types.contains("application/x-moz-file"))
        setIsDraggingFile(containsFiles)
        setWindowDragging(true)
      }
      const handleDragEnd = (e) => {
        if (!e.relatedTarget) {
          setWindowDragging(false)
        }
      }

      const handleDragLeave = (e) => {
        if (!e.relatedTarget) {
          setWindowDragging(false)
        }
      }
      // window.addEventListener("dragstart", handler)
      window.addEventListener("dragenter", handleDragEnter)
      window.addEventListener("dragstart", handleDragStart)
      window.addEventListener("dragend", handleDragEnd)
      window.addEventListener("dragleave", handleDragLeave)
      return () => {
        window.addEventListener("dragenter", handleDragEnter)
        window.removeEventListener("dragstart", handleDragStart)
        window.removeEventListener("dragend", handleDragEnd)
        window.removeEventListener("dragleave", handleDragLeave)
      }
    }, [disableDrop])

    useEffect(() => {
      if (disableDrop || !isDraggingFile) {
        return () => {}
      }

      const handleDragOver = (e) => {
        e.preventDefault()
        if (fileFieldRef.current.contains(e.target)) {
          // eslint-disable-next-line no-param-reassign
          e.dataTransfer.dropEffect = "copy"
          setDraggingInside(true)
        } else {
          setDraggingInside(false)
        }
      }

      const handleDrop = (e) => {
        if (!disableDrop && fileFieldRef.current.contains(e.target)) {
          e.preventDefault()
          e.stopPropagation()

          const { files } = e.dataTransfer
          if (files.length > 0) {
            setChosenFile(files[0])
          }
        }
        setDraggingInside(false)
        setWindowDragging(false)
        setIsDraggingFile(false)
      }

      window.addEventListener("dragover", handleDragOver)
      window.addEventListener("drop", handleDrop)
      return () => {
        window.removeEventListener("dragover", handleDragOver)
        window.removeEventListener("drop", handleDrop)
      }
    }, [disableDrop, isDraggingFile])

    return (
      <FormControl style={style} id={id} error={error} fullWidth={fullWidth}>
        <div
          ref={fileFieldRef}
          className={clsx(
            "scm-file-field",
            !disableDrop && "scm-file-field--droppable"
          )}
        >
          <label htmlFor={name}>
            <input
              accept={accept || (image ? "image/png, image/jpeg" : null)}
              ref={fileRef}
              hidden
              name={name}
              type="file"
              onChange={handleFileChange}
            />
            <div className="scm-file-field__content">
              <div className="scm-file-field__action">
                <Button
                  action={chooseFileBtnRef}
                  className="scm-file-field__button-choose"
                  variant="outlined"
                  component="span"
                  color="primary"
                  size="small"
                  onClick={handleClickChoose}
                  disableElevation
                >
                  Choose File
                </Button>
                {!disableDrop && (
                  <Typography style={{ marginTop: "0.5rem" }} variant="h5">
                    or drag and drop here
                  </Typography>
                )}
              </div>
              {valueExists && (
                <div className="spacer" style={{ height: "0.5rem" }} />
              )}
              <div className="scm-file-field__info">
                {!valueExists && (
                  <div className="scm-file-field__no-file">No file chosen.</div>
                )}
                {filename && (
                  <>
                    <div className="scm-file-field__file-name">
                      {filename}
                      {fileSizeFormat && (
                        <>
                          {" "}
                          ({fileSizeFormat[0]}&nbsp;{fileSizeFormat[1]})
                        </>
                      )}
                    </div>
                    <IconButton
                      color="primary"
                      title="Clear"
                      size="small"
                      onClick={handleClear}
                    >
                      <CancelIcon />
                    </IconButton>
                  </>
                )}
                {changed && (
                  <IconButton
                    color="primary"
                    title="Reset"
                    size="small"
                    onClick={onReset}
                  >
                    <ResetIcon />
                  </IconButton>
                )}
              </div>
            </div>
            {uploading && (
              <div className="scm-file-field__upload-wrapper">
                <span>Uploading...</span>
                <LinearProgressWithLabel
                  className="scm-file-field__upload-progress"
                  variant={progressing ? "determinate" : "indeterminate"}
                  value={progressing ? progress : null}
                />
              </div>
            )}
            {valueExists && image && (
              <div className="scm-file-field__image-wrapper">
                <ImageDisplay
                  elevation={0}
                  showOpenInNewTab
                  alt="Design of Product image"
                  className="scm-file-field__image"
                  {...imageDisplayProp}
                  src={fileUrl}
                />
              </div>
            )}
          </label>
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
          {renderDragNotice && (
            <div
              className={clsx(
                "scm-file-field__drop-notice",
                showDragNotice && "scm-file-field__drop-notice--show",
                draggingInside && "scm-file-field__drop-notice--dragging-inside"
              )}
            >
              <span>Drop your files here</span>
            </div>
          )}
        </div>
      </FormControl>
    )
  }
)

FileField.propTypes = {
  id: t.string,
  accept: t.string,
  helperText: t.string,
  error: t.bool,
  onChange: t.func,
  onReset: t.func,
  name: t.string,
  value: t.string,
  image: t.bool,
  changed: t.bool,
  normalizeImagePath: t.bool,
  disableDrop: t.bool,
  fullWidth: t.bool,
  imageDisplayProp: t.shape({}),
}

FileField.defaultProps = {
  id: null,
  accept: null,
  value: null,
  changed: false,
  name: "",
  onChange: () => {},
  onReset: () => {},
  helperText: "",
  error: false,
  image: false,
  normalizeImagePath: false,
  disableDrop: false,
  fullWidth: false,
  imageDisplayProp: {},
}

FileField.displayName = "FileField"

export default FileField
