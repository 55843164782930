import { axios, ENDPOINT } from "./constants"
import {
  newDictToStringParam,
  handleSilentApiError,
  getQueryForAllAndCount,
  adjustDefaultParams,
  getDataAndCountFromResponse,
} from "./utils"

const ITEM_DETAIL_QUERY = `
idUser
name
email
status
employeeNumber
npwpNumber
nikNumber
jknNumber
jmoNumber
originalAddress
residenceAddress
nonActiveDate
birthDate
bloodType
religion {
  idReligion
  name
}
startWorkDate
passport
sectionId
sections {
  section {
    idSection
    name
  }
}
seq
marriageStatus {
  idMarriageStatus
  marriageStatusName
}
employeeStatus {
  idEmployeeStatus
  employeeStatusName
}
phoneNumber
emergencyPhoneNumber
roles {
  idRole
  name
}
employeeStatusDate {
  employeeStatusId
  employeeStatus {
    employeeStatusName
  }
  dateStart
}
avatar
avatarLastUpdate
documents {
  fileName
  description
  expiredAt
}
`

export default class ApiUser {
  static async get(inpParams = {}) {
    const inpParamsCopy = { ...inpParams }
    // inpParamsCopy.ignoreCase = true
    const params = adjustDefaultParams(inpParamsCopy)
    let query = `
    query {
      allUserImpl {
        ${ITEM_DETAIL_QUERY}
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)
    return getDataAndCountFromResponse(response)
  }

  static async getCurrentUser(token) {
    const config = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : null

    const response = await axios.post(
      ENDPOINT.QUERY,
      {
        query: `
        query {
          getMyProfile {
            idUser
            name
            employeeNumber
            roles {
              idRole
              name
              endpoints {
                idEndpoint
                backendName
                frontendName
              }
            }
          }
        }`,
      },
      config
    )
    handleSilentApiError(response)
    return { data: response.data.data.getMyProfile }
  }

  static async create(payload) {
    const payloadAsParam = newDictToStringParam(payload)
    const response = await axios.post(ENDPOINT.CREATE, {
      query: `
      mutation {
        saveUserImpl(${payloadAsParam}) {
          userImpl {
            idUser
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.saveUserImpl.userImpl }
  }

  static async getItem(id) {
    const response = await axios.post(ENDPOINT.QUERY, {
      query: `
      query { 
        getUserImpl (id: ${id}) {
          ${ITEM_DETAIL_QUERY}
        }
      }`,
    })
    handleSilentApiError(response)

    const {
      birthDate,
      startWorkDate,
      nonActiveDate,
      ...otherItem
    } = response.data.data.getUserImpl

    return {
      data: {
        ...otherItem,
        birthDate: birthDate?.slice(0, 10),
        startWorkDate: startWorkDate?.slice(0, 10),
        nonActiveDate: nonActiveDate?.slice(0, 10),
      },
    }
  }

  static async delete(idUser) {
    const response = await axios.post(ENDPOINT.DELETE, {
      query: `
      mutation {
        deleteUserImpl(idUser: ${idUser}) { 
          userImpl { 
            idUser 
          } 
        } 
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.deleteUserImpl.userImpl }
  }

  static async update(idUser, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idUser: parseInt(idUser, 10),
    })

    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateUserImpl (${payloadAsParam}) {
          userImpl {
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
    })
    handleSilentApiError(response)

    const {
      birthDate,
      startWorkDate,
      ...otherItem
    } = response.data.data.updateUserImpl.userImpl

    return {
      data: {
        ...otherItem,
        birthDate: birthDate?.slice(0, 10),
        startWorkDate: startWorkDate?.slice(0, 10) ?? "",
      },
    }
  }

  static async updateSelf(idUser, payload) {
    const payloadAsParam = newDictToStringParam({
      ...payload,
      idUser: parseInt(idUser, 10),
    })

    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation {
        updateSelfImpl (${payloadAsParam}) {
          userImpl {
            ${ITEM_DETAIL_QUERY}
          }
        }
      }`,
    })
    handleSilentApiError(response)

    return { data: response.data.data.updateSelfImpl.userImpl }
  }
}
