/* eslint-disable react/prop-types */

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import clsx from "clsx"

import AppBarMaterialUi from "@material-ui/core/AppBar"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import AccountCircle from "@material-ui/icons/AccountCircle"
import DashboardIcon from "@material-ui/icons/DashboardOutlined"

import { makeStyles, withStyles } from "@material-ui/core/styles"

const MENU_FIELDS_PROFILE = "USER_PROFILE"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#439A97",
  },
  headerUserContainer: {
    display: "flex",
    alignItems: "center",
  },
  loginButton: {
    color: "white",
    textTransform: "none",
    borderColor: "white",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: 10,
      horizontal: "right",
    }}
    {...props}
  />
))

/**
 * If logged in but no userData, circular loader will be displayed in the user status
 * meaning it is fetching data.
 * If no userData.avatar, a placeholder avatar will be used.
 * @param {} param0
 */

export default function AppBar({
  authenticated,
  userData,
  onDialogChoose,
  onLoginButtonClick,
  onLogoutButtonClick,
  classes: parentClasses,
  style: parentStyles,
  onDashboardButtonClick,
}) {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const userAvatarUrl = (userData || {}).avatar
  const userNickname = (userData || {}).name

  useEffect(() => {
    if (!authenticated) {
      setAnchorEl(null)
    }
  }, [authenticated])

  const userEl = userAvatarUrl ? (
    <Avatar alt={userNickname} src={userAvatarUrl} />
  ) : (
    <AccountCircle style={{ fontSize: "40px" }} />
  )

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDialogChoosed = (mode) => {
    onDialogChoose(mode)
    handleClose()
  }

  const handleLogoutButtonClick = (event) => {
    handleClose()
    onLogoutButtonClick(event)
  }

  const authElem = authenticated ? (
    <div className={classes.headerUserContainer}>
      <Typography variant="body1">Hello, {userNickname}</Typography>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        {userEl}
      </IconButton>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleDialogChoosed(MENU_FIELDS_PROFILE)}>
          User Profile
        </MenuItem>
        <Divider />
        <Box mx={2} my={1}>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleLogoutButtonClick}
          >
            Log out
          </Button>
        </Box>
      </StyledMenu>
    </div>
  ) : (
    <Button
      variant="outlined"
      disableElevation
      classes={{ root: classes.loginButton }}
      onClick={onLoginButtonClick}
    >
      Log in
    </Button>
  )

  return (
    <AppBarMaterialUi
      style={parentStyles}
      position="fixed"
      className={clsx(classes.appBar, parentClasses?.appBar)}
    >
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={onDashboardButtonClick}
        >
          <DashboardIcon />
        </IconButton>
        <div style={{ flexGrow: 1 }} />
        {authElem}
      </Toolbar>
    </AppBarMaterialUi>
  )
}

AppBar.propTypes = {
  authenticated: PropTypes.bool,
  classes: PropTypes.shape({
    appBar: PropTypes.string,
  }),
  onDialogChoose: PropTypes.func,
  onLoginButtonClick: PropTypes.func,
  onLogoutButtonClick: PropTypes.func,
  onDashboardButtonClick: PropTypes.func,
  style: PropTypes.shape({}),
  userData: PropTypes.shape({}),
}

AppBar.defaultProps = {
  authenticated: false,
  userData: null,
  onDialogChoose: () => {},
  onLoginButtonClick: () => {},
  onLogoutButtonClick: () => {},
  onDashboardButtonClick: () => {},
  classes: {},
  style: {},
}
