/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
  useEffect,
} from "react"
import useCheckPermission from "utils/auth/useCheckPermission"

import { WORK_ORDER_BLANK_FORM } from "components/work-order/constants"
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom"
import ApiWorkOrder from "services/api-work-order"
import ApiCompanyInformation from "services/api-company-information"
import { ROUTE_DELIVERY_NOTE, ROUTE_WORK_ORDER } from "constants/routeConstants"
import PERMISSION_CODES from "constants/permission-codes"
import RouteBreadcrumb from "components/page-layout/breadcrumbs/RouteBreadcrumb"
import WOItemDisplay from "components/work-order/WOItemDisplay"
import useShowSnackbar from "utils/snackbar-hooks"
import { Button } from "@material-ui/core"
import WODeleteDialog from "components/work-order/WODeleteDialog"
import WorkOrderUpdatePage from "./WorkOrderUpdatePage"

export default function WorkOrderItemPage() {
  const { checkWithoutUpdateState } = useCheckPermission()

  const [showSnackbar, showSnackbarLoading] = useShowSnackbar()

  const [item, setItem] = useState(WORK_ORDER_BLANK_FORM)
  const [fetchingInitData, setFetchingInitData] = useState(false)
  const [itemExist, setItemExist] = useState(false)
  const [flagRefetch, setFlagRefetch] = useState(false)
  const [workOrderSections, setWorkOrderSections] = useState([])

  const [companyInfo, setCompanyInfo] = useState({})
  const reduceProductionHour = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.REDUCE_ESTIMATE_PRODUCTION_HOUR],
      }),
    [checkWithoutUpdateState]
  )

  const history = useHistory()

  const routeMatch = useRouteMatch()
  const id = routeMatch?.params?.id

  const loading = fetchingInitData

  const getActiveAndCleanup = () => {
    let active = true
    const checkActive = () => active
    const cleanup = () => {
      active = false
    }
    return { checkActive, cleanup }
  }

  const fetchInitialData = useCallback(async (itemId, checkActive) => {
    setFetchingInitData(true)

    let tempItemExists = true
    let tempWoItem = null
    let tempCompanyInfo = null

    async function fetchWorkOrderData() {
      const { data: responseData } = await ApiWorkOrder.getItem(itemId)
      if (!responseData) {
        tempItemExists = false
      } else {
        tempWoItem = responseData
      }
    }

    async function fetchCompanyInfo() {
      const response = await ApiCompanyInformation.get()
      tempCompanyInfo = response.data
    }

    await Promise.all([fetchWorkOrderData(), fetchCompanyInfo()])

    if (!checkActive()) return
    setItemExist(tempItemExists)
    setItem(tempWoItem)
    setCompanyInfo(tempCompanyInfo)

    setFetchingInitData(false)
  }, [])

  useEffect(() => {
    let active = true

    ;(async () => {
      const response = await ApiWorkOrder.getWorkOrderSection()
      if (!active) return
      setWorkOrderSections(response.data)
    })()

    return () => {
      active = false
    }
  }, [])

  useLayoutEffect(() => {
    const { checkActive, cleanup } = getActiveAndCleanup()

    fetchInitialData(id, checkActive)

    return cleanup
  }, [fetchInitialData, id, flagRefetch])

  const ITEM_ROUTE = `${ROUTE_WORK_ORDER}/${id}`
  const EDIT_ROUTE = `${ROUTE_WORK_ORDER}/${id}/edit`

  const initiateEdit = () => {
    history.push(EDIT_ROUTE)
  }

  const initiateCreateDN = (val) => {
    console.log({ val })

    localStorage.setItem("outgoingItem", JSON.stringify(val))
    history.push(`${ROUTE_DELIVERY_NOTE}/create`)
  }

  const changeStatus = async (status) => {
    const response = await ApiWorkOrder.updateStatus(id, status)
    if (response) {
      setFlagRefetch((flag) => !flag)
    }
  }

  const canEditPage = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.WRITE_WORK_ORDER],
      }),
    [checkWithoutUpdateState]
  )

  const canReadPrice = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.READ_WORK_ORDER_PRICE],
      }),
    [checkWithoutUpdateState]
  )

  const canCreateDeliveryNote = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.WRITE_DELIVERY_NOTES],
      }),
    [checkWithoutUpdateState]
  )

  const canCloseWorkOrderProject = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.CLOSE_WORK_ORDER_PROJECT],
      }),
    [checkWithoutUpdateState]
  )

  const canUpdateTrialDate = useMemo(
    () =>
      checkWithoutUpdateState({
        permissions: [PERMISSION_CODES.WRITE_WORK_ORDER_TRIAL_DATE],
      }),
    [checkWithoutUpdateState]
  )

  const [openDialog, setOpenDialog] = useState(false)

  const handleClosed = async (event) => {
    const shouldDelete = event.confirm
    setOpenDialog(false)

    if (shouldDelete) {
      showSnackbarLoading("Deleting...")
      const response = await ApiWorkOrder.updateStatus(id, "deleted")
      if (response) {
        showSnackbar("Work order data was deleted successfully")
        history.push(ROUTE_WORK_ORDER)
      }
    }
  }

  const updateTrialDate = async (trialDate) => {
    showSnackbarLoading("Updating...")
    const response = await ApiWorkOrder.updateTrialDate(id, trialDate)
    if (response) {
      showSnackbar("Trial date updated successfully")
      setFlagRefetch(!flagRefetch)
    }
  }

  const closeWorkOrderProject = async () => {
    showSnackbarLoading("Closing project...")
    const response = await ApiWorkOrder.closeWorkOrderProject(id)
    if (response) {
      showSnackbar("Project closed successfully")
      setFlagRefetch(!flagRefetch)
    }
  }

  const mainContent = (
    <>
      <Switch>
        <RouteBreadcrumb name="Edit Work Order" path={EDIT_ROUTE}>
          <WorkOrderUpdatePage id={id} formData={item} setFormData={setItem} />
        </RouteBreadcrumb>
        <Route exact path={ITEM_ROUTE}>
          <WOItemDisplay
            data={item}
            onConfirmEdit={initiateEdit}
            showEditAndDelete={canEditPage}
            customerData={
              item?.salesQuotation?.slsTargetCustomer || item?.customer || null
            }
            canCreateDeliveryNote={canCreateDeliveryNote}
            companyInfo={companyInfo}
            onConfirmStatusChange={changeStatus}
            onConfirmDelete={() => setOpenDialog(true)}
            workOrderSections={workOrderSections}
            onCofirmCreateDN={initiateCreateDN}
            showPrice={canReadPrice}
            canUpdateTrialDate={canUpdateTrialDate}
            onTrialDateUpdate={updateTrialDate}
            canCloseWorkOrderProject={canCloseWorkOrderProject}
            closeWorkOrderProject={closeWorkOrderProject}
            reduceProductionHour={reduceProductionHour}
          />
        </Route>
      </Switch>
      <WODeleteDialog
        open={openDialog}
        onClose={handleClosed}
        woNumber={item?.woNumber}
      />
    </>
  )

  const notFoundDisplay = (
    <div>
      Work order data not found.
      <Button
        component={Link}
        style={{ display: "block", margin: "auto", width: "100px" }}
        to={ROUTE_WORK_ORDER}
        variant="contained"
        disableElevation
        color="primary"
      >
        Back
      </Button>
    </div>
  )

  if (loading) {
    return <>Loading...</>
  }

  return itemExist ? mainContent : notFoundDisplay
}
