/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */

import React from "react"

function GeneralTablePrint({
  fields,
  styles = {},
  textAlign = "left",
  transparentLabel = false,
  fullWidth = true,
}) {
  const baseStyles = fullWidth ? { width: "100%" } : {}
  return (
    <table style={{ ...baseStyles, ...styles }}>
      <tbody>
        {fields.map((field, index) => (
          <tr key={index}>
            <td style={{ verticalAlign: "top", whiteSpace: "nowrap" }}>
              <strong
                style={{
                  display: "flex",
                  fontSize: "9pt",
                  justifyContent: "space-between",
                  color: transparentLabel ? "white" : "black",
                }}
              >
                <span style={{ color: transparentLabel ? "white" : "black" }}>
                  {field.label}
                </span>
                :
              </strong>
            </td>
            <td style={{ textAlign, fontSize: "9pt" }}>{field.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default GeneralTablePrint
