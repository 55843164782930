/* eslint-disable react/prop-types */
import { makeStyles, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme) => ({
  filterHeaderTitle: {
    color: theme.palette.sectionTitle.main,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  filterBox: {
    border: `1px solid #bccce3`,
    padding: theme.spacing(1),
    borderRadius: 5,
    marginBottom: theme.spacing(3),
  },
}))
function FilterBox({ children }) {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.filterHeaderTitle} variant="body1">
        Filter
      </Typography>
      <div className={classes.filterBox}>{children}</div>
    </>
  )
}

export default FilterBox
