/* eslint-disable react/prop-types */
import { Button, CircularProgress } from "@material-ui/core"
import axios from "axios"
import React, { useState } from "react"
import { getFileUploadLinkFromDB } from "utils/text-utils"

function FileDownloader({
  link,
  text = "Download",
  title = "file",
  download = true,
}) {
  // remark
  const [loading, setLoading] = useState(false)
  const downloadAttachment = () => {
    if (!download) {
      window.open(getFileUploadLinkFromDB(link))
      return
    }
    // Do these things together:
    // - Fetch PDF data from backend
    // - Wait library to be loaded

    let isDownloaded = false
    const fetchAndDownload = async () => {
      const [response, { default: FileDownload }] = await Promise.all([
        axios.get(getFileUploadLinkFromDB(link), {
          responseType: "blob",
        }),
        import("js-file-download"),
      ])
      const extension = link.split(".")[link.split(".").length - 1]
      FileDownload(response.data, `${title}.${extension}`)
      isDownloaded = true
      setLoading(false)
    }

    fetchAndDownload()
    setTimeout(() => {
      if (!isDownloaded) {
        setLoading(true)
      }
    }, 200)
  }
  return (
    <Button
      style={{ position: "relative" }}
      variant="outlined"
      color="primary"
      onClick={downloadAttachment}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress
            style={{ margin: "auto" }}
            variant="indeterminate"
            size={20}
            thickness={2}
          />
        </div>
      )}
      <span style={{ opacity: loading ? 0 : 1 }}>{text}</span>
    </Button>
  )
}

export default FileDownloader
