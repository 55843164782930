import environment from "utils/environment"

const REGEX_URL_FILENAME = /(?:.*)\/(.*)$/
const REGEX_REMOVE_FIRST_RANDOM_STRING = /.*_(.*)/
const REGEX_FILE_NAME_EXT = /(?<name>.*)\.(?<ext>.*)$/

export const getFilenameFromApiUrl = (
  url,
  { ignoreUnderscore = false } = {}
) => {
  // eslint-disable-next-line no-param-reassign
  url = url || ""

  const matches = url.match(REGEX_URL_FILENAME)
  if (!matches) return url
  const filename = matches[1]

  if (ignoreUnderscore) {
    const nameOnlyMatches = url.match(REGEX_REMOVE_FIRST_RANDOM_STRING)
    if (nameOnlyMatches) {
      return nameOnlyMatches[1]
    }
  }
  return filename
}

export const trimFilename = (filename, limit = 25) => {
  let name = filename
  let ext = ""
  const match = (filename || "").match(REGEX_FILE_NAME_EXT)
  if (match) {
    name = match.groups.name
    ext = match.groups.ext
  }

  const extLength = ext.length
  const hasExtension = extLength > 0
  const nameLength = Math.min(
    name.length,
    limit - (hasExtension && extLength + 1)
  )

  let trimmedName = name.slice(0, nameLength)
  if (trimmedName.length < name.length) {
    // add ellipsis
    trimmedName = trimmedName.slice(0, trimmedName.length - 3)
    trimmedName += "..."
  }
  if (hasExtension) {
    return `${trimmedName}.${ext}`
  }
  return trimmedName
}

export const getApiFileUrl = (url) => {
  return `${environment.baseRootApi}${url}`
}

export const normalizeApiImageUrl = (url) => {
  const host = environment.baseRootApi
  const filename = getFilenameFromApiUrl(url)
  const path = "/apiimage/"
  return `${host}${path}${filename}`
}

// adds an underscore before extension if it has no underscore yet.
// For some reason, backend cannot recognize a filename if it has no underscore.
export const appendUnderscore = (filename) => {
  let name = filename
  let ext = ""
  const match = (filename || "").match(REGEX_FILE_NAME_EXT)
  if (match) {
    name = match.groups.name
    ext = match.groups.ext
  }
  if (name.includes("_")) {
    return filename
  }
  return `${name}_.${ext}`
}

export const formatDateGeneric = (dateString, config) => {
  const date = new Date(dateString)
  let { lang, format, render } = config || {}
  lang = lang || "en"
  format = format || {
    year: "numeric",
    month: "short",
    day: "numeric",
  }
  render = render || (({ y, m, d }) => `${d} ${m} ${y}`)

  if (Number.isNaN(date.getTime())) {
    return ""
  }

  const dtFormat = new Intl.DateTimeFormat(lang, format)
  const [{ value: m }, , { value: d }, , { value: y }] = dtFormat.formatToParts(
    date
  )
  return render({ d, m, y })
}

export const formatDateColonShort = (dateString) =>
  formatDateGeneric(dateString, {
    render: ({ d, m, y }) => `${d}-${m}-${y}`,
  })

export const formatDateSpacedShort = (dateString) =>
  formatDateGeneric(dateString, {
    format: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
  })

export const formatDateSpacedLong = (dateString) =>
  formatDateGeneric(dateString, {
    format: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  })

/** Given DB value with the format <hash>_<name>.<ext>,
 * return <name>.<ext> */

export const getFileNameFromDB = (dbValue) => {
  if (!dbValue) {
    return ""
  }
  const underscoreSplit = dbValue.split("_")
  const hasName = underscoreSplit.length >= 2

  return hasName ? underscoreSplit.slice(1).join("_") : dbValue
}

const getBaseFileUploadLinkFromDB = (dbValue, prefix = "apiupload/") => {
  if (!dbValue) {
    return ""
  }
  const underscoreSplit = dbValue.split("_")
  const hash = underscoreSplit[0]

  const hasName = underscoreSplit.length >= 2
  let serverFileName

  if (hasName) {
    const dotSplit = dbValue.split(".")
    const hasExtension = dotSplit.length >= 2
    const ext = dotSplit[dotSplit.length - 1]
    serverFileName = hasExtension ? `${hash}.${ext}` : hash
  } else {
    serverFileName = dbValue
  }

  return getApiFileUrl(`${prefix}${serverFileName}`)
}

/** Given DB value with the format <hash>_<name>.<ext>,
 * return <API_LINK>/apiupload/<hash>.<ext>
 *
 * Example: 88a2c60767b5af6ba339c9fff8ff477eb_test.png
 * returns: http://localhost:5000/apiupload/88a2c60767b5af6ba339c9fff8ff477eb.png
 */

export const getFileUploadLinkFromDB = (dbValue) => {
  return getBaseFileUploadLinkFromDB(dbValue, "apiupload/")
}

/** Given DB value with the format <hash>_<name>.<ext>,
 * return <API_LINK>/apiimage/<hash>.<ext>
 *
 * Example: 88a2c60767b5af6ba339c9fff8ff477eb_test.png
 * returns: http://localhost:5000/apiimage/88a2c60767b5af6ba339c9fff8ff477eb.png
 */

export const getImageUploadLinkFromDB = (dbValue) => {
  return getBaseFileUploadLinkFromDB(dbValue, "apiimage/")
}

export const capitalizeWords = (sentence) => {
  return (
    sentence.toLowerCase().charAt(0).toUpperCase() + sentence.slice(1)
  ).replace(/\s[a-z]/g, (subWord) => subWord.toUpperCase())
}
