import { axios, ENDPOINT } from "./constants"
import {
  handleSilentApiError,
  adjustDefaultParams,
  getQueryForAllAndCount,
} from "./utils"

const ITEM_DETAIL_QUERY = `
  companyName
  phone
  email
  address
  NPWP
  fax
  commentSalesQuotation`

export default class ApiCompanyInformation {
  static async get(inpParams = {}) {
    const params = adjustDefaultParams(inpParams)
    let query = `
    query { 
      getCompanyInformationImpl{
        ${ITEM_DETAIL_QUERY}
      }
    }`
    query = getQueryForAllAndCount(query, params)

    const response = await axios.post(ENDPOINT.QUERY, {
      query,
    })
    handleSilentApiError(response)

    return { data: response.data.data.getCompanyInformationImpl }
  }

  static async update(payload) {
    const response = await axios.post(ENDPOINT.UPDATE, {
      query: `
      mutation (
        $companyName: String!
        $phone: String!
        $email: String!
        $address: String!
        $npwp: String!
        $fax: String!
        $commentSalesQuotation: String!) {

        updateCompanyInformationImpl(
          companyName: $companyName
          phone: $phone
          email: $email
          address: $address
          npwp: $npwp
          fax: $fax
          commentSalesQuotation: $commentSalesQuotation
        ) {
            
          companyInformationImpl {
            companyName
            phone
            email
            address
            NPWP
            fax
            commentSalesQuotation
          }
        }
      }`,
      variables: payload,
    })
    handleSilentApiError(response)

    return {
      data:
        response.data.data.updateCompanyInformationImpl.companyInformationImpl,
    }
  }
}
