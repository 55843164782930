/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core"
import { simplifyInvoiceQuotItems } from "components/delivery-note/utils"
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
  useEffect,
} from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { transformProperty } from "utils/objects"
import InvoiceFormContext from "./InvoiceFormContex"
import SalesQuotationPicker from "./SalesQuotationPicker"
import InvoiceExportRow from "./InvoiceExportRow"

const InvoiceExportTable = forwardRef(
  (
    {
      fetchSalesQuotationData = () => [],
      initialValue = {},
      fetchInitSalesQuotationData,
    },
    ref
  ) => {
    const [initialFormData] = useState(() => {
      return (initialValue?.productDescriptions ?? []).map((item, index) => {
        return {
          __id: index,
          description: item.description,
          unitPrice: item.unitPrice,
          quantity: item.quantity,
          unit: item.unit,
          slsQuot: item.slsEstimate?.slsQuot,
          slsEstimateId: item.slsEstimateId,
          totalCost: item.unitPrice,
          remark: item.remark,
          netWeight: item.netWeight,
          grossWeight: item.grossWeight,
          totalPackingList: item.totalPackingList,
        }
      })
    })

    const { setProductDescriptions } = useContext(InvoiceFormContext)

    const { register, control, errors, getValues, setValue } = useForm({
      defaultValues: { productDescriptions: initialFormData },
    })

    const { fields } = useFieldArray({
      control,
      name: "productDescriptions",
      keyName: "__id",
    })

    const [selectedSlsQuotations, setSelectedSlsQuotations] = useState([])
    const [deletedSlsEstimates, setDeletedSlsEstimates] = useState([])

    useEffect(() => {
      let active = true

      ;(async () => {
        const idSalesQuotations = Array.from(
          new Set(
            (initialValue?.productDescriptions ?? []).map(
              (item) => item.slsEstimate?.slsQuotId
            )
          )
        )
        const response = await fetchInitSalesQuotationData({
          idSalesQuotations,
        })
        if (!active) return

        setSelectedSlsQuotations(response)
      })()

      return () => {
        active = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
      const temp = []
      const prevValues = getValues().productDescriptions || []

      selectedSlsQuotations.forEach((curr) => {
        const currentList = curr.estimateList || []

        currentList
          // .filter((estList) => estList.paymentStatus !== 3)
          .forEach((estList) => {
            const { totalCost } = simplifyInvoiceQuotItems([estList])[0]
            const current = prevValues.filter(
              (item) => +item.slsEstimateId === estList.idSalesEstimate
            )[0]
            const fromInit = initialFormData.filter(
              (item) => item.slsEstimateId === estList.idSalesEstimate
            )[0]
            temp.push({
              ...estList,
              description:
                current?.description ||
                fromInit?.description ||
                estList.description,
              remark: current?.remark || fromInit?.remark || "",
              slsEstimateId: estList.idSalesEstimate,
              totalCost: estList.totalAmount || totalCost,
              unitPrice: estList.totalAmount || totalCost,
              slsQuot: {
                slsQuotNumber: curr.slsQuotNumber,
                poNumber: estList.slsPurchaseOrder?.poNumber,
              },
              grossWeight: current?.grossWeight || fromInit?.grossWeight || 0,
              netWeight: current?.netWeight || fromInit?.netWeight || 0,
              totalPackingList:
                current?.totalPackingList || fromInit?.totalPackingList || "",
            })
          })
      })
      setProductDescriptions(temp)
      setValue("productDescriptions", temp)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSlsQuotations])

    const onDeleteItem = (index) => {
      const curr = fields[index]
      const arrCopy = [...deletedSlsEstimates]
      arrCopy.push(curr)

      const selectedSlsQuotCopy = selectedSlsQuotations
        .map((sq) => {
          const { estimateList } = sq
          const newEstimateList = estimateList.filter(
            (est) => est.idSalesEstimate !== curr.slsEstimateId
          )
          return {
            ...sq,
            estimateList: newEstimateList,
          }
        })
        .filter((sq) => sq.estimateList.length)

      setDeletedSlsEstimates(
        arrCopy.filter((se) => {
          const currSq = selectedSlsQuotCopy.filter(
            (sq) => sq.slsQuotNumber === se.slsQuot.slsQuotNumber
          )[0]
          return currSq
        })
      )
      setSelectedSlsQuotations(selectedSlsQuotCopy)
    }

    useImperativeHandle(ref, () => ({
      getFormData: () => {
        const data = getValues()
        const dataCopy = (data?.productDescriptions ?? []).map((product) => {
          transformProperty(
            product,
            ["quantity", "slsEstimateId", "sequence"],
            parseInt
          )
          transformProperty(
            product,
            ["unitPrice", "netWeight", "grossWeight"],
            parseFloat
          )
          // eslint-disable-next-line no-param-reassign
          if (!product.dnDetailId) delete product.dnDetailId
          // eslint-disable-next-line no-param-reassign
          if (!product.quantity) delete product.quantity
          return product
        })
        return { productDescriptions: dataCopy }
      },
      submitForm: () => {},
    }))

    const onAddSlsQuotation = (slsQuot) => {
      const dataCopy = [...selectedSlsQuotations]
      dataCopy.push(slsQuot)
      setSelectedSlsQuotations(dataCopy)
    }

    const onDeleteSlsQuotation = (slsQuotNumber) => {
      const dataCopy = [...selectedSlsQuotations]
      const newData = dataCopy.filter((item) => item !== slsQuotNumber)
      const deletedCopy = [...deletedSlsEstimates]
      const newDeleted = deletedCopy.filter(
        (item) => item.slsQuot.slsQuotNumber !== slsQuotNumber
      )
      setDeletedSlsEstimates(newDeleted)
      setSelectedSlsQuotations(newData)
    }
    useEffect(() => {
      setValue("productDescriptions", initialFormData)
      setProductDescriptions(initialFormData)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: "9rem" }}>
                  Sls Quot number
                </TableCell>
                <TableCell>PO Number</TableCell>
                <TableCell style={{ minWidth: "24rem" }}>Description</TableCell>
                <TableCell style={{ width: "7rem" }}>Quantity</TableCell>
                <TableCell style={{ width: "7rem" }}>UoM</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Sequence</TableCell>
                <TableCell>Remark</TableCell>
                <TableCell>Net Weight</TableCell>
                <TableCell>Gross Weight</TableCell>
                <TableCell>Total</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.length === 0 ? (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }} colSpan={13}>
                    No Data
                  </TableCell>
                </TableRow>
              ) : null}
              {fields.map((item, index) => {
                return (
                  <InvoiceExportRow
                    key={item.__id}
                    register={register}
                    errors={errors}
                    item={item}
                    index={index}
                    onDeleteButtonClick={onDeleteItem}
                  />
                )
              })}
            </TableBody>
            <TableFooter>
              <SalesQuotationPicker
                onAddItem={onAddSlsQuotation}
                selectedSalesQuotations={selectedSlsQuotations}
                onDeleteItem={onDeleteSlsQuotation}
                fetchSalesQuotationData={fetchSalesQuotationData}
                label="Quotation Number"
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    )
  }
)

export default InvoiceExportTable
