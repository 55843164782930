/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { forwardRef } from "react"
import "../../assets/print/invoice-page.css"
import HeaderPrint from "components/common/print/HeaderPrint"
import { useAlignStyles } from "components/bill-of-material/BOMItemPrintStyles"
import GeneralTablePrint from "components/common/print/GeneralTablePrint"
import clsx from "clsx"
import { formatDate } from "utils/form-data"
import { makeStyles } from "@material-ui/core"
import InvoiceTypography from "./InvoiceTypography"

const useStyles = makeStyles(() => ({
  generalTable: {
    fontSize: "10pt",
    padding: "4px",
  },
  generalInformationTable: {
    marginTop: "1em",
    marginBottom: "1em",
    border: "2px solid black",
    padding: "4px",
  },
  descriptionTable: {
    borderCollapse: "collapse",
  },
  page: {
    descriptionTable: {
      "& tbody > *:last-child > *": {
        borderBottom: "0.3mm solid black",
      },
    },
  },
}))

const PackingListPrint = forwardRef(
  (
    {
      companyInformation,
      data,
      productDescription,
      dnNumberUnique = [],
      poNumberUnique = [],
      // poDateUnique = [],
      withRefQuot = false,
      withPaymentPercentage = false,
    },
    ref
  ) => {
    const alignClasses = useAlignStyles()
    const classes = useStyles()
    const theadColumn = [
      { attr: "no", label: "No" },
      { attr: "description", label: "Description" },
      { attr: "quantity", label: "Quantity" },
      {
        attr: "netWeight",
        label: "NW (KG)",
        style: { width: "9rem", textAlign: "center" },
      },
      {
        attr: "grossWeight",
        label: "GW (KG)",
        style: { width: "9rem", textAlign: "center" },
      },
    ]

    const totalStyle =
      data.discount || data.vat ? {} : { borderTop: "2px solid black" }

    const generalFields = [
      {
        label: <span>DN No</span>,
        value: (
          <strong>
            {dnNumberUnique.length > 0 ? (
              <ul style={{ listStyle: "none", marginInlineStart: 0 }}>
                {dnNumberUnique.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            ) : (
              "-"
            )}
          </strong>
        ),
      },
    ]
    if (withRefQuot) {
      generalFields.push({
        label: <span>Ref Quot</span>,
        value: (
          <strong>
            {poNumberUnique.length > 0 ? (
              <ul style={{ listStyle: "none", marginInlineStart: 0 }}>
                {poNumberUnique.map((item) => (
                  <li key={item.poNumber}>{item.slsQuotNumber}</li>
                ))}
              </ul>
            ) : (
              "-"
            )}
          </strong>
        ),
      })
    }

    const headerElement = (
      <>
        <HeaderPrint
          showAddress
          companyInformation={companyInformation}
          itemDetail={
            <>
              <table style={{ zIndex: 9999, marginBottom: "0.5em" }}>
                <tbody>
                  <tr>
                    <td>
                      <strong
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ marginRight: "4px" }}>PL. NO</span>:
                      </strong>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <strong>{data?.invoiceNumber}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Date</span>:
                      </strong>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <strong>{formatDate(data?.date)}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          }
        />
      </>
    )

    const bodyElement = (
      <>
        <InvoiceTypography type="PACKING LIST" />

        <div
          style={{
            border: "1px solid black",
            marginTop: "1em",
            width: "72px",
            textAlign: "center",
          }}
        >
          <strong>Bill to :</strong>
        </div>

        <div
          className={alignClasses.row}
          style={{ justifyContent: "space-between" }}
        >
          <div
            style={{
              marginRight: "2em",
            }}
          >
            <div
              style={{
                flex: 3,
              }}
              className={classes.generalInformationTable}
            >
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    fontSize: "10pt",
                    top: "-12px",
                    backgroundColor: "white",
                    textAlign: "center",
                    width: "96px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Customer</span>
                </div>
                <table className={classes.generalTable}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Name</strong>
                      </td>
                      <td>
                        <strong>: {data?.customer?.name}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Address</strong>
                      </td>
                      <td>: {data?.address}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Phone</strong>
                      </td>
                      <td>
                        : {data.phone}{" "}
                        <span style={{ marginLeft: "1em" }}>
                          <strong>Attn</strong> : Manager Accounting
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={classes.generalInformationTable}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <strong style={{ fontSize: "9pt" }}>PO No:</strong>
                  {poNumberUnique.length > 0
                    ? poNumberUnique.map((item) => (
                        <p
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            fontSize: "9pt",
                          }}
                          key={item.poNumber}
                        >
                          {item.poNumber}
                        </p>
                      ))
                    : "-"}
                </div>
                <div>
                  <strong style={{ fontSize: "9pt" }}>PO Date:</strong>
                  {poNumberUnique.length > 0
                    ? poNumberUnique.map((item) => (
                        <p
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            fontSize: "9pt",
                          }}
                          key={item.poNumber}
                        >
                          {formatDate(item.poDate)}
                        </p>
                      ))
                    : "-"}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ flex: 2, height: "100%" }}
            className={classes.generalInformationTable}
          >
            <GeneralTablePrint fields={generalFields} textAlign="right" />
          </div>
        </div>

        <table
          style={{ width: "100%", border: "2px solid black" }}
          className={clsx(
            classes.descriptionTable,
            "table-product-description"
          )}
        >
          <thead>
            <tr>
              {theadColumn.map((column) => {
                return (
                  <th style={{ fontSize: "10pt" }} key={column.label}>
                    {column.label}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {data?.payment && (
              <tr>
                {theadColumn.map((_, index) => {
                  let val = ""
                  if (index === 1) {
                    const percentage = data.paymentPercentage
                      ? `${data.paymentPercentage}%`
                      : ""
                    val = (
                      <strong style={{ textDecoration: "underline" }}>
                        {data.payment} {withPaymentPercentage && percentage}
                      </strong>
                    )
                  }
                  return <td key={index}>{val}</td>
                })}
              </tr>
            )}
            {data?.notes && (
              <tr>
                {theadColumn.map((_, index) => {
                  let val = ""
                  if (index === 1) {
                    val = (
                      <strong
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {data.notes}
                      </strong>
                    )
                  }
                  return <td key={index}>{val}</td>
                })}
              </tr>
            )}
            {productDescription.map((product) => {
              return (
                <React.Fragment key={product.no}>
                  {withRefQuot && (
                    <tr>
                      {theadColumn.map((_, index) => {
                        let val = ""
                        if (index === 1) {
                          val = <strong>{product.projectName}</strong>
                        }
                        return <td key={index}>{val}</td>
                      })}
                    </tr>
                  )}
                  <tr>
                    {theadColumn.map((column) => {
                      const addedStyles = column.style ? column.style : {}

                      return (
                        <td
                          style={{
                            fontSize: "10pt",
                            padding: "4px",
                            ...addedStyles,
                            verticalAlign: "top",
                          }}
                          key={column.attr}
                        >
                          {product[column.attr]}
                        </td>
                      )
                    })}
                  </tr>
                </React.Fragment>
              )
            })}
            <tr>
              <td />
              <td>
                {data.remarks && (
                  <>
                    <p style={{ marginTop: "1em" }}>Remarks:</p>
                    <p style={{ whiteSpace: "pre-line" }}>{data.remarks}</p>
                  </>
                )}
              </td>
              {theadColumn.map((column) => {
                if (column.attr !== "no" && column.attr !== "description")
                  return <td />
                return null
              })}
            </tr>
            <tr>
              <td
                colSpan={theadColumn.length - 2}
                style={{
                  ...totalStyle,
                }}
                className="border-left-white border-bottom-white"
              />
              <td
                style={{ padding: "4px", textAlign: "center", ...totalStyle }}
              >
                <strong>
                  {new Intl.NumberFormat().format(
                    productDescription?.reduce((a, b) => a + b.netWeight, 0)
                  )}
                </strong>
              </td>
              <td style={{ padding: "4px", ...totalStyle }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <strong>
                    {new Intl.NumberFormat().format(
                      productDescription?.reduce((a, b) => a + b.grossWeight, 0)
                    )}
                  </strong>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          className={alignClasses.row}
          style={{ marginTop: "1em", breakInside: "avoid" }}
        >
          <div
            className={clsx(
              alignClasses.columnLeftAlign,
              alignClasses.rowChildBasisMedium
            )}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <ol
                      style={{ listStyleType: "none", margin: 0, padding: 0 }}
                    >
                      {productDescription?.map((pd, i) => {
                        return (
                          <li style={{ margin: 0, padding: 0 }} key={i}>
                            {pd.totalPackingList}
                          </li>
                        )
                      })}
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Net Weight</strong>
                  </td>
                  <td>
                    <ol
                      style={{ listStyleType: "none", margin: 0, padding: 0 }}
                    >
                      <li style={{ margin: 0, padding: 0 }}>
                        <strong>
                          {productDescription.reduce(
                            (a, b) => a + b.netWeight,
                            0
                          )}{" "}
                          KGS
                        </strong>
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Gross Weight</strong>
                  </td>
                  <td>
                    <ol
                      style={{ listStyleType: "none", margin: 0, padding: 0 }}
                    >
                      <li style={{ margin: 0, padding: 0 }}>
                        <strong>
                          {productDescription.reduce(
                            (a, b) => a + b.grossWeight,
                            0
                          )}{" "}
                          KGS
                        </strong>
                      </li>
                    </ol>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    )

    return (
      <div className={clsx("print-container")} ref={ref}>
        <table>
          {/* <thead> */}
          <tr>
            <th>{headerElement}</th>
          </tr>
          {/* </thead> */}
          <tbody>
            <tr>
              <td>{bodyElement}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
)

export default PackingListPrint
