/* eslint-disable react/prop-types */
import { Paper } from "@material-ui/core"
import InvoiceForm from "components/invoice/InvoiceForm"
import NotAllowedNotice from "components/NotAllowedNotice"
import { ROUTE_INVOICE_ROOT } from "constants/routeConstants"
import PERMISSION_CODES from "constants/permission-codes"
import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import ApiAuthorizedSignature from "services/api-authorized-signature"
import ApiCustomer from "services/api-customer"
import ApiDeliveryNotes from "services/api-delivery-notes"
import ApiInvoice from "services/api-invoice"
import ApiCurrency from "services/api-master-data-currency"
import ApiSalesQuotation from "services/api-sales-quotation"
import useCheckPermission from "utils/auth/useCheckPermission"
import {
  sendAndHandleInvalidToken,
  useWrapHandleInvalidToken,
} from "utils/invalid-token"
import useShowSnackbar from "utils/snackbar-hooks"

const PAGE_PERMISSIONS = [PERMISSION_CODES.WRITE_INVOICE]

const fetchDeliveryNote = async ({
  searchText = "",
  customerId = "",
  currencyId = "",
}) =>
  (
    (await ApiDeliveryNotes.get({
      searchText,
      customerId,
      currencyId,
      fromColumns: ["dnNumber"],
      limit: 10,
      notPaid: true,
      fixed: true,
    })) || []
  ).data
const fetchInitDeliveryNote = async ({ ids }) =>
  ((await ApiDeliveryNotes.get({ ids })) || []).data
const fetchInitPOData = async ({ ids }) =>
  ((await ApiSalesQuotation.getPOForInvoice({ ids })) || []).data
const fetchCurrency = async () => ((await ApiCurrency.get()) || []).data

const fetchCustomer = async () => ((await ApiCustomer.get()) || []).data

const fetchAuthorizedSignature = async () =>
  ((await ApiAuthorizedSignature.get()) || []).data

const fetchInitSalesQuotation = async ({ idSalesQuotations }) =>
  (await ApiSalesQuotation.getSQForInvoice({ idSalesQuotations })).data

const fetchSalesQuotation = async ({ poNumber, rateInId, customerId }) =>
  (
    (await ApiSalesQuotation.getSQForInvoice({
      searchText: poNumber,
      fromColumns: ["slsQuotNumber"],
      status: "execute",
      limit: 10,
      rateInId,
      customerId,
    })) || []
  ).data
const fetchPOData = async ({
  poNumber = "",
  rateInId = "",
  customerId = "",
  ...otherParams
}) =>
  (
    (await ApiSalesQuotation.getPOForInvoice({
      poNumber,
      rateInId,
      customerId,
      paymentStatuses: [1, 2],
      ...otherParams,
    })) || []
  ).data

function InvoiceUpdatePage({ formData, setFormData, id }) {
  const history = useHistory()

  const [showSnackbar, showSnackbarLoading, hideSnackbar] = useShowSnackbar()

  const { checkWithoutUpdateState } = useCheckPermission()

  const wrappedFetchDeliveryNoteData = useWrapHandleInvalidToken(
    fetchDeliveryNote,
    () => []
  )
  const wrappedFetchInitDeliveryNoteData = useWrapHandleInvalidToken(
    fetchInitDeliveryNote,
    () => []
  )
  const wrappedFetchInitPOData = useWrapHandleInvalidToken(
    fetchInitPOData,
    () => []
  )
  const wrappedFetchPOData = useWrapHandleInvalidToken(fetchPOData, () => [])

  const wrappedFetchCurrency = useWrapHandleInvalidToken(
    fetchCurrency,
    () => []
  )

  const wrappedFetchCustomerData = useWrapHandleInvalidToken(
    fetchCustomer,
    () => []
  )

  const wrappedFetchAuthorizedSignature = useWrapHandleInvalidToken(
    fetchAuthorizedSignature,
    () => []
  )

  const wrappedFetchSalesQuotationData = useWrapHandleInvalidToken(
    fetchSalesQuotation,
    () => []
  )

  const allowed = useMemo(
    () => checkWithoutUpdateState({ permissions: PAGE_PERMISSIONS }),
    [checkWithoutUpdateState]
  )

  const handleCancel = () => {
    history.push(ROUTE_INVOICE_ROOT)
  }
  if (!allowed) {
    return (
      <Paper style={{ maxWidth: "800px" }}>
        <NotAllowedNotice />
      </Paper>
    )
  }

  const onSubmit = async (data) => {
    showSnackbarLoading("Updating invoice data...")

    let fetchState = {}

    try {
      fetchState = await sendAndHandleInvalidToken(() =>
        ApiInvoice.update(id, data)
      )
    } catch (err) {
      console.log(err)
      showSnackbar(err?.errors[0]?.message ?? "Cannot update invoice")
      return
    }

    if (fetchState.success) {
      showSnackbar("Invoice data successfully updated")
      setFormData(fetchState.response.data)
      history.push(`${ROUTE_INVOICE_ROOT}/${id}`)
    } else {
      hideSnackbar()
    }
  }
  return (
    <InvoiceForm
      onCancel={handleCancel}
      initialValue={formData}
      setFormData={setFormData}
      onSubmit={onSubmit}
      fetchDeliveryNoteData={wrappedFetchDeliveryNoteData}
      fetchCurrencyData={wrappedFetchCurrency}
      fetchCustomerData={wrappedFetchCustomerData}
      fetchAuthorizedSignatureData={wrappedFetchAuthorizedSignature}
      isUpdate
      fetchPOData={wrappedFetchPOData}
      fetchSalesQuotationData={wrappedFetchSalesQuotationData}
      fetchInitDeliveryNoteData={wrappedFetchInitDeliveryNoteData}
      fetchInitPOData={wrappedFetchInitPOData}
      fetchInitSalesQuotationData={fetchInitSalesQuotation}
    />
  )
}

export default InvoiceUpdatePage
