import { ENDPOINT, axios } from "./constants"

export default class ApiBankAccount {
  static async get() {
    const query = `
        query {
            getBankAccountImpl {
              banks {
                bankName
                bankAddress
                swiftCode
                accounts {
                  currency
                  accountNumber
                }
              }
              accountName
            }
        }`

    const response = await axios.post(ENDPOINT.QUERY, { query })

    return { data: response.data.data.getBankAccountImpl }
  }
}
