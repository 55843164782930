/* eslint-disable react/forbid-prop-types */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
} from "react"
import PropTypes from "prop-types"
import isNil from "lodash/isNil"
import isObject from "lodash/isObject"

import { makeStyles } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useTextFieldStyles } from "components/common/input/InputLabel"

const useStyles = makeStyles({
  inputNotFocused: {
    "&, &:disabled": {
      opacity: 0,
    },
  },
})

const AsyncDropdown = forwardRef(
  (
    {
      id,
      defaultValue,
      value,
      // eslint-disable-next-line react/prop-types
      variant,

      onChange,
      onInputChange,

      fetchData,
      renderOption,
      inputLabel,
      getOptionSelected: parentGetOptionSelected,
      renderInputField,
      getOptionLabel,
      filterOptions,

      error,
      helperText,

      options: parentOptions,
      loading: parentLoading,
      // eslint-disable-next-line react/prop-types
      inputProps = {},

      ...otherProps
    },
    ref
  ) => {
    const optionsHandledByParent = typeof fetchData !== "function"

    const [options, setOptions] = useState(parentOptions || [])

    // Update options everytime parent options is updated
    useEffect(() => {
      if (optionsHandledByParent) {
        setOptions(parentOptions || [])
      }
    }, [optionsHandledByParent, parentOptions])

    // Update loading everytime parent's loading is udpated
    const computedLoading = !isNil(parentLoading) ? parentLoading : false
    const [loading, setLoading] = useState(computedLoading)

    useEffect(() => {
      if (!isNil(parentLoading)) {
        setLoading(parentLoading)
      }
    }, [parentLoading])

    const [open, setOpen] = useState(false)
    const [inputValue, setInputValue] = useState("")

    useEffect(() => {
      if (optionsHandledByParent) {
        setLoading(computedLoading)
      }
    }, [optionsHandledByParent, computedLoading])

    useEffect(() => {
      if (!open || optionsHandledByParent) {
        return () => {}
      }

      let active = true
      const fetchDataOnOpen = async () => {
        const data = await fetchData(inputValue)
        if (!active) {
          return
        }
        const copy = [...data]
        if (defaultValue && parentGetOptionSelected) {
          const idx = data.findIndex(parentGetOptionSelected)
          if (idx === -1) {
            copy.push(defaultValue)
          }
        }
        setOptions(copy)
        setLoading(false)
      }

      setLoading(true)
      fetchDataOnOpen()

      return () => {
        active = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, inputValue, optionsHandledByParent, fetchData])

    const handleInputChange = (event, inpValue) => {
      if (typeof onInputChange === "function") {
        onInputChange(inpValue)
      }
      setInputValue(inpValue)
    }

    const elementIsObject = useMemo(() => {
      if (isNil(value)) {
        if (options.length >= 0) {
          const firstOption = options[0]
          return isObject(firstOption)
        }
        return false
      }
      return isObject(value)
    }, [value, options])

    const getOptionSelected = useMemo(() => {
      if (typeof parentGetOptionSelected === "function" || elementIsObject) {
        return parentGetOptionSelected
      }
      return (option, currValue) => option === currValue
    }, [parentGetOptionSelected, elementIsObject])

    // Get options for Autocomplete element.
    // If value is not inside the parent options,
    // add it to the usedOptions.

    const usedOptions = useMemo(() => {
      if (isNil(value)) {
        return options
      }

      const valueInsideOptions = options.filter((option) =>
        getOptionSelected(option, value)
      )[0]
      if (valueInsideOptions) {
        return options
      }
      return [value, ...options]
    }, [value, options, getOptionSelected])

    const inputLabelClasses = useTextFieldStyles()
    const [focus, setFocus] = useState(false)

    const classes = useStyles()
    const shrinked = !!(inputValue || !isNil(value)) || focus

    const renderInput = useCallback(
      (params) => (
        <TextField
          {...params}
          {...inputProps}
          label={inputLabel}
          size="small"
          InputLabelProps={{
            classes: { shrink: inputLabelClasses.labelShrinked },
            shrink: shrinked,
          }}
          variant={variant}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            onFocus: () => setFocus(true),
            onBlur: () => setFocus(false),
            classes: {
              input:
                focus || typeof renderInputField !== "function"
                  ? ""
                  : classes.inputNotFocused,
            },
            startAdornment:
              typeof renderInputField === "function"
                ? renderInputField({ focused: focus, value })
                : null,
            endAdornment: (
              <>
                {loading && focus ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        inputLabel,
        inputLabelClasses.labelShrinked,
        shrinked,
        renderInputField,
        classes.inputNotFocused,
        loading,
        focus,
        value,
        error,
        helperText,
        variant,
      ]
    )

    return (
      <Autocomplete
        {...otherProps}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue)
        }}
        ref={ref}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        id={id}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        filterOptions={
          typeof filterOptions === "function" ? filterOptions : undefined
        }
        options={usedOptions}
        loading={loading}
        renderInput={renderInput}
      />
    )
  }
)

AsyncDropdown.propTypes = {
  id: PropTypes.string,

  value: PropTypes.any,
  defaultValue: PropTypes.any,

  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  fetchData: PropTypes.func,
  renderOption: PropTypes.func,
  filterOptions: PropTypes.func,
  inputLabel: PropTypes.node,
  renderInputField: PropTypes.func,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,

  // Since values can be of arbitrary type, options can be too.
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  loading: PropTypes.bool,

  error: PropTypes.bool,
  helperText: PropTypes.node,
}

AsyncDropdown.defaultProps = {
  id: null,
  value: null,
  onInputChange: () => {},
  fetchData: null,
  renderOption: null,
  inputLabel: null,
  filterOptions: null,
  renderInputField: null,
  getOptionSelected: null,
  getOptionLabel: null,
  options: null,
  loading: null,
  error: false,
  helperText: null,
  defaultValue: null,
}

export default AsyncDropdown
